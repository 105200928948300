import React, { useEffect, useState, useContext } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";

import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import da from "date-fns/locale/da";

import globalContext from "../context/global/globalContext";

import { API_URL, API_ENDPOINT_STRING } from "../constants";

import { useStyles } from "../styles";

import axios from "axios";

const Participant = ({ participant, personCount }) => {
  const classes = useStyles();

  const { token, order, setParticipantsGlobalData } = useContext(globalContext);

  const [participantData, setParticipantData] = useState(participant);

  const [locationData, setLocationData] = useState([]);

  var formatter = new Intl.NumberFormat("da-DK", {
    style: "currency",
    currency: "DKK",
  });

  const defaultBirthdateChild = new Date(
    new Date().setFullYear(new Date().getFullYear() - participant.age)
  );
  const defaultBirthdateAdult = new Date(
    new Date().setFullYear(new Date().getFullYear() - participant.age)
  );

  const getLocations = async (direction) => {
    let locations = locationData;
    try {
      const { data } = await axios({
        url: `${API_URL}/wp-json/${API_ENDPOINT_STRING}/v1/orders/participants/locations?token=${token}&direction=${direction}&order_id=${order.id}&participant_id=${participant.participant_id}`,
        method: "GET",
        data: [],
      });
      locations[direction] = data.result;
      setLocationData(locationData);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getLocations("departure");
    getLocations("return");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    var tempParticipant = participantData;
    tempParticipant.services_ordered.map((subService, subServiceKey) =>
      subService.results.forEach((subServiceItem, subServiceItemKey) => {
        if (subServiceItem.selected)
          tempParticipant.services_ordered[subServiceKey].currentSelected =
            JSON.stringify({
              id: subServiceItem.service_price_id,
              cost: subServiceItem.service_price,
            });
      })
    );

    if (tempParticipant.gender === "C")
      tempParticipant.birthdate = defaultBirthdateChild.getTime();
    else {
      tempParticipant.birthdate = defaultBirthdateAdult.getTime();
    }

    setParticipantData(tempParticipant);
    setParticipantsGlobalData(tempParticipant, personCount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      locationData.return &&
      locationData.departure &&
      (!participantData.departure_location_id ||
        !participantData.return_location_id)
    ) {
      var pushedValue = participantData;

      pushedValue.departure_location_id = locationData.departure[0]?.location_id
        ? locationData.departure[0].location_id
        : null;
      pushedValue.return_location_id = locationData.return[0]?.location_id
        ? locationData.return[0].location_id
        : null;

      setParticipantData(pushedValue);
      setParticipantsGlobalData(pushedValue, personCount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationData, participantData]);

  const handleChange = (value, oname) => {
    let pushedValue = { ...participantData };
    pushedValue[oname] = value;
    setParticipantData(pushedValue);
    setParticipantsGlobalData(pushedValue, personCount);
  };

  const handleDateChange = (dateValue, oname) => {
    let pushedValue = { ...participantData };
    pushedValue[oname] = dateValue;
    setParticipantData(pushedValue);
    setParticipantsGlobalData(pushedValue, personCount);
  };

  const [pickerStatus, setPickerStatus] = useState(false);

  return (
    <div className="my-6 p-4 rounded border border-solid border-1 border-gray-400">
      <div className="grid grid-cols-12">
        <div className="col-span-12 mb-4">
          <h4 className="font-semibold text-lg text-center bg-gray-200 border border-gray-400 rounded p-2 mb-4">
            {participant.full_name
              ? participant.full_name
              : participant.age > 17
              ? "Voksen"
              : "Barn"}
            {participant.gender === "C" && (
              <span className="font-light ml-2 text-lg">
                {participant.age} år
              </span>
            )}
            <span className="font-light ml-2 text-lg">
              (Deltager {personCount + 1})
            </span>
          </h4>
        </div>
      </div>
      <div
        className={
          participantData.gender !== "C"
            ? "grid grid-cols-1 lg:grid-cols-3 gap-4"
            : "grid grid-cols-1 lg:grid-cols-2 gap-4"
        }
      >
        <TextField
          InputLabelProps={{ shrink: true }}
          className={classes.formControl}
          id="name"
          required={true}
          placeholder="Indtast fulde navn"
          label="Fulde navn"
          variant="outlined"
          type="text"
          name="full_name"
          value={participantData.full_name}
          onChange={(e) => {
            handleChange(e.target.value, "full_name");
          }}
        />
        {participantData.gender !== "C" && (
          <TextField
            InputLabelProps={{ shrink: true }}
            className={classes.formControl}
            required={true}
            id="age"
            label="Alder"
            variant="outlined"
            type="number"
            name="age"
            defaultValue={participantData.age}
            onChange={(e) => {
              let ageDate = new Date().setFullYear(
                new Date().getFullYear() - e.target.value
              );
              handleChange(e.target.value, "age");
              handleDateChange(ageDate, "birthdate");
            }}
          />
        )}
        {participantData.gender === "C" && (
          <MuiPickersUtilsProvider
            className="col-span-1"
            utils={DateFnsUtils}
            locale={da}
          >
            <KeyboardDatePicker
              InputLabelProps={{ shrink: true }}
              onClick={() => setPickerStatus(true)}
              onClose={() => setPickerStatus(false)}
              required={participantData.gender === "C" ? true : false}
              open={pickerStatus}
              clearable={false}
              disableFuture={true}
              autoOk={true}
              animateYearScrolling={true}
              defaultValue={participantData.birthdate}
              label="Fødselsdato"
              value={participantData.birthdate}
              onChange={(date) => {
                handleDateChange(date.getTime(), "birthdate");
              }}
              format="dd/MM/yyyy"
              inputVariant="outlined"
              variant="dialog"
            />
          </MuiPickersUtilsProvider>
        )}
        {participantData.gender !== "C" && (
          <FormControl className={classes.formControl} variant="outlined">
            <InputLabel id="køn">Køn</InputLabel>
            <Select
              id="køn"
              label="køn"
              value={participantData.gender}
              required={true}
              onChange={(e) => {
                handleChange(e.target.value, "gender");
              }}
            >
              <MenuItem disabled>-- Køn --</MenuItem>
              <MenuItem value="M">Mand</MenuItem>
              <MenuItem value="F">Kvinde</MenuItem>
            </Select>
          </FormControl>
        )}
      </div>
      {!!(
        participantData?.full_name &&
        participantData?.age &&
        participantData?.gender
      ) && (
        <div className={"pt-4"}>
          <h4 className="font-regular text-base text-center bg-gray-50 border border-color-gray-100 rounded p-1 mb-6">
            Ydelser & Tilvalg
          </h4>
          {participant?.services_ordered?.map((serviceitem, servicekey) => (
            <div className="mb-4" key={servicekey}>
              <div className="grid grid-cols-12">
                <div className="col-span-12 lg:col-span-5">
                  <h6 className="text-lg mt-0 lg:mt-2 mb-4 lg:mb-0">
                    {serviceitem.title}
                  </h6>
                </div>
                <FormControl
                  key={serviceitem.id}
                  variant="outlined"
                  className="col-span-12 lg:col-span-7"
                >
                  <InputLabel className="mb-4 lg:mb-0" id={serviceitem.title}>
                    {serviceitem.title}
                  </InputLabel>
                  <Select
                    id={serviceitem.title}
                    value={
                      participantData &&
                      participantData.services_new &&
                      participantData.services_new[servicekey] &&
                      participantData.services_new[servicekey].item
                        ? participantData.services_new[servicekey].item
                        : serviceitem.currentSelected
                        ? serviceitem.currentSelected
                        : serviceitem.standard
                        ? JSON.stringify({
                            id: serviceitem.standard,
                            cost: "0.0",
                          })
                        : false
                    }
                    onChange={(e) => {
                      var pushedValue = participantData;

                      pushedValue.services_new = pushedValue.services_new ?? [];

                      pushedValue.services_new[servicekey] = {};

                      pushedValue.services[servicekey]
                        ? (pushedValue.services_new[servicekey] = {
                            service_group_id: serviceitem.id,
                            item: e.target.value,
                          })
                        : (pushedValue.services_new[servicekey] = {});
                      setParticipantData(pushedValue);
                      setParticipantsGlobalData(pushedValue, personCount);
                    }}
                  >
                    <MenuItem disabled>-- Vælg --</MenuItem>
                    {!serviceitem.standard && (
                      <MenuItem value={false}>Fravalgt</MenuItem>
                    )}
                    {serviceitem.results.map((subItem, subItemIndex) => (
                      <MenuItem
                        className={classes.formControl}
                        value={JSON.stringify({
                          id: subItem.service_price_id,
                          cost: subItem.service_price,
                        })}
                        key={subItemIndex}
                      >
                        {subItem.description} (
                        {formatter.format(subItem.service_price)})
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          ))}
          <div className="pt-4">
            {!!(
              locationData?.departure?.length && locationData?.return?.length
            ) && (
              <div className="mb-4">
                <h4 className="font-regular text-base text-center bg-gray-50 border border border-color-gray-100 rounded p-1 mb-6">
                  Transport opsamling/afsætningssteder
                </h4>
                {!!locationData?.departure && (
                  <div className="grid grid-cols-12 mb-4">
                    <div className="col-span-12 lg:col-span-5">
                      <h6 className="text-lg mt-0 lg:mt-2 mb-4 lg:mb-0">
                        Opsamlingssted for afrejse
                      </h6>
                    </div>
                    <FormControl
                      variant="outlined"
                      className="col-span-12 lg:col-span-7"
                    >
                      <Select
                        id="location_departure"
                        value={participantData.departure_location_id}
                        onChange={(e) => {
                          var pushedValue = participantData;

                          pushedValue.departure_location_id = e.target.value;

                          setParticipantData(pushedValue);
                          setParticipantsGlobalData(pushedValue, personCount);
                        }}
                      >
                        <MenuItem disabled>
                          -- Vælg afrejse opsamlingssted --
                        </MenuItem>
                        {locationData.departure.map((subItem, subItemIndex) => (
                          <MenuItem
                            className="{classes.formControl}"
                            value={subItem.location_id}
                            key={subItemIndex}
                          >
                            {subItem.location_name} - kl.
                            {subItem.location_pickup_time_display} (
                            {formatter.format(subItem.pickup_price)})
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                )}
                {!!locationData?.return && (
                  <div className="grid grid-cols-12">
                    <div className="col-span-12 lg:col-span-5">
                      <h6 className="text-lg mt-0 lg:mt-2 mb-4 lg:mb-0">
                        Afsætningssted for hjemrejse
                      </h6>
                    </div>
                    <FormControl
                      variant="outlined"
                      className="col-span-12 lg:col-span-7"
                    >
                      <Select
                        id="location_departure"
                        value={participantData.return_location_id}
                        onChange={(e) => {
                          var pushedValue = participantData;

                          pushedValue.return_location_id = e.target.value;

                          setParticipantData(pushedValue);
                          setParticipantsGlobalData(pushedValue, personCount);
                        }}
                      >
                        <MenuItem disabled>
                          -- Vælg hjemrejse afsætningssted --
                        </MenuItem>
                        {locationData.return.map((subItem, subItemIndex) => (
                          <MenuItem
                            className={classes.formControl}
                            value={subItem.location_id}
                            key={subItemIndex}
                          >
                            {subItem.location_name} - kl.
                            {subItem.location_pickup_time_display} (
                            {formatter.format(subItem.pickup_price)})
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Participant;

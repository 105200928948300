//@ts-check

export const SortTypes = Object.freeze({
  ASC: 0,
  DESC: 1,
  POP: 2,
});

export const SortText = Object.freeze({
  ASC: "Pris (Lav til høj)",
  DESC: "Pris (Høj til lav)",
  POP: "Popularitet",
});

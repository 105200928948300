import { Button, TextField } from "@material-ui/core";
import React, { useEffect, useContext, useState } from "react";
import { scroller } from "react-scroll";
import { useHistory } from "react-router-dom";
import globalContext from "../context/global/globalContext";
import Participant from "./Participant";
import "animate.css";
import { SET_CURRENT_COMBINATIONS } from "../context/types";

const OrderForm = ({ tailwindCSS }) => {
  const history = useHistory();

  const {
    order,
    orderRequestStatus,
    customerData,
    createCustomerData,
    setCustomerData,
    participantsGlobalData,
    setParticipantsDataFinal,
    dispatch,
  } = useContext(globalContext);

  const [customerSaved, setCustomerSaved] = useState(false);

  const [customerNameFirst, setCustomerNameFirst] = useState(
    customerData.first_name ? customerData.first_name : ""
  );
  const [customerNameLast, setCustomerNameLast] = useState(
    customerData.last_name ? customerData.last_name : ""
  );
  const [customerEmail, setCustomerEmail] = useState(
    customerData.email_address ? customerData.email_address : ""
  );

  const [customerStatus, setCustomerStatus] = useState(
    customerEmail && customerNameFirst && customerNameLast ? true : false
  );

  useEffect(() => {
    scroller.scrollTo("orderform", {
      duration: 1200,
      offset: -350,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  }, []);

  const handleCustomerSave = () => {
    setCustomerSaved(true);
    let customerDataTemp = customerData ? customerData : {};
    customerDataTemp.pin_code = order.pin_code;
    customerDataTemp.first_name = customerNameFirst;
    customerDataTemp.last_name = customerNameLast;
    customerDataTemp.email_address = customerEmail;
    if (customerEmail && customerNameFirst && customerNameLast) {
      setCustomerStatus(true);
    }
    if (!customerStatus) {
      createCustomerData(customerDataTemp);
    } else {
      setCustomerData(customerDataTemp);
    }
    window.setTimeout(() => {
      setCustomerSaved(false);
    }, 3000);
  };

  return (
    <div id="orderform" className={` ${tailwindCSS}`}>
      <h2 className="p-0 text-2xl text text-center font-bold mb-4">
        Deltagere
      </h2>
      <div className="p-4 border border-solid rounded border-gray-400">
        {orderRequestStatus && (
          <p className="text-center text-red-600">
            Denne rejse er kun til rådighed via forespørgsel. Du vil blive
            kontaktet af vores personale når du har fuldført din bestilling
          </p>
        )}
        <h4 className="font-semibold text-sm mb-4">Bestiller Information</h4>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
          <div>
            <TextField
              InputLabelProps={{ shrink: true }}
              fullWidth
              id="nameFirst"
              label="Fornavn"
              variant="outlined"
              type="text"
              name="nameFirst"
              value={customerNameFirst}
              onChange={(e) => {
                setCustomerNameFirst(e.target.value);
              }}
            />
          </div>
          <div>
            <TextField
              InputLabelProps={{ shrink: true }}
              fullWidth
              id="nameLast"
              label="Efternavn"
              variant="outlined"
              type="text"
              name="nameLast"
              value={customerNameLast}
              onChange={(e) => {
                setCustomerNameLast(e.target.value);
              }}
            />
          </div>
          <div>
            <TextField
              InputLabelProps={{ shrink: true }}
              fullWidth
              id="email"
              label="Email"
              variant="outlined"
              type="email"
              name="email"
              value={customerEmail}
              onChange={(e) => {
                setCustomerEmail(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="mt-4 text-right">
          {customerStatus ? (
            <Button
              disabled={
                customerEmail && customerNameFirst && customerNameLast
                  ? false
                  : true
              }
              size="large"
              onClick={() => {
                handleCustomerSave();
              }}
              color="secondary"
              variant="contained"
            >
              Opdater bestiller
            </Button>
          ) : (
            <Button
              disabled={
                customerEmail && customerNameFirst && customerNameLast
                  ? false
                  : true
              }
              size="large"
              onClick={() => {
                handleCustomerSave();
              }}
              color="secondary"
              variant="contained"
            >
              Gem bestiller
            </Button>
          )}
        </div>
        {customerStatus && customerSaved && (
          <div className="mt-4 text-center">
            <h6 className="text-base animate__animated animate__shakeX animate__slow">
              Bestiller gemt
            </h6>
          </div>
        )}
      </div>
      {customerStatus &&
        order?.participants &&
        order.participants.map((item, index) => (
          <Participant key={index} participant={item} personCount={index} />
        ))}

      <div className="mt-4 flex justify-between">
        <Button
          onClick={() => {
            dispatch({
              type: SET_CURRENT_COMBINATIONS,
              payload: false,
            });

            history.goBack();
          }}
          variant="outlined"
          size="large"
        >
          Tilbage
        </Button>
        <Button
          disabled={
            order?.participants.length === participantsGlobalData.length
              ? false
              : true
          }
          onClick={() => {
            setParticipantsDataFinal(participantsGlobalData);
            history.push("orderdetails" + history.location.search);
          }}
          color="primary"
          variant="contained"
          size="large"
        >
          Fortsæt
        </Button>
      </div>
    </div>
  );
};

export default OrderForm;

import { Button, TextField, Checkbox } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { scroller } from "react-scroll";
import globalContext from "../context/global/globalContext";
import { useHistory } from "react-router-dom";
import { API_URL, API_ENDPOINT_STRING } from "../constants";

import axios from "axios";

const OrderAccountHolderForm = ({ tailwindCSS }) => {
  const history = useHistory();
  const { token, order, customerData, currentSubSeason } =
    useContext(globalContext);

  const [customerNameFirst, setCustomerNameFirst] = useState(
    customerData.first_name ? customerData.first_name : ""
  );
  const [customerNameLast, setCustomerNameLast] = useState(
    customerData.last_name ? customerData.last_name : ""
  );
  const [customerEmail, setCustomerEmail] = useState(
    customerData.email_address ? customerData.email_address : ""
  );
  const [customerPhone, setCustomerPhone] = useState(
    customerData.phone ? customerData.phone : ""
  );
  const [customerAddress1, setCustomerAddress1] = useState(
    customerData.address1 ? customerData.address1 : ""
  );
  const [customerAddress2, setCustomerAddress2] = useState(
    customerData.address2 ? customerData.address2 : ""
  );
  const [customerZip, setCustomerZip] = useState(
    customerData.zip ? customerData.zip : ""
  );
  const [customerCity, setCustomerCity] = useState(
    customerData.city ? customerData.city : ""
  );

  const [termsChecked, setTermsChecked] = useState(false);

  const handle_termsChange = (event) => {
    setTermsChecked(event.target.checked);
  };
  const [privacyChecked, setPrivacyChecked] = useState(false);

  const handle_privacyChange = (event) => {
    setPrivacyChecked(event.target.checked);
  };

  const [customerStatus, setCustomerStatus] = useState(false);

  const [customerObject, setCustomerObject] = useState({});

  useEffect(() => {
    scroller.scrollTo("orderaccountholderform", {
      duration: 1200,
      offset: -350,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  }, []);

  useEffect(() => {
    if (
      customerEmail &&
      customerNameFirst &&
      customerNameLast &&
      customerPhone &&
      customerAddress1 &&
      customerZip &&
      customerCity
    ) {
      setCustomerStatus(true);
      let customerDataTemp = customerData ? customerData : {};
      customerDataTemp.pin_code = order.pin_code;
      customerDataTemp.first_name = customerNameFirst;
      customerDataTemp.last_name = customerNameLast;
      customerDataTemp.email_address = customerEmail;
      customerDataTemp.phone = customerPhone;
      customerDataTemp.address1 = customerAddress1;
      customerDataTemp.address2 = customerAddress2;
      customerDataTemp.zip = customerZip;
      customerDataTemp.city = customerCity;
      setCustomerObject(customerDataTemp);
    } else {
      setCustomerStatus(false);
    }
  }, [
    customerNameFirst,
    customerNameLast,
    customerEmail,
    customerPhone,
    customerAddress1,
    customerAddress2,
    customerZip,
    customerCity,
    customerData,
    customerObject,
    order,
  ]);

  const handleCustomerFinalize = () => {
    let customerDataTemp = customerData ? customerData : {};
    customerDataTemp.pin_code = order.pin_code;
    customerDataTemp.first_name = customerNameFirst;
    customerDataTemp.last_name = customerNameLast;
    customerDataTemp.email_address = customerEmail;
    customerDataTemp.phone = customerPhone;
    customerDataTemp.address1 = customerAddress1;
    customerDataTemp.address2 = customerAddress2;
    customerDataTemp.zip = customerZip;
    customerDataTemp.city = customerCity;
    setCustomerObject(customerDataTemp);

    const postData = {
      token: token,
      order_id: order.id,
      customer: JSON.stringify(customerDataTemp),
    };

    const postDataConfirm = {
      token: token,
      order_id: order.id,
      pincode: order.pin_code,
    };

    axios
      .post(
        `${API_URL}/wp-json/${API_ENDPOINT_STRING}/v1/orders/customers/patch`,
        postData
      )
      .then(() => {
        axios
          .post(
            `${API_URL}/wp-json/${API_ENDPOINT_STRING}/v1/orders/finalize`,
            postDataConfirm
          )
          .then(() => {
            history.push(
              "orderconfirmation?orderid=" +
                order.id +
                "&pincode=" +
                order.pin_code +
                "&subseason=" +
                currentSubSeason
            );
          });
      });
  };

  return (
    <div id="orderaccountholderform" className={` ${tailwindCSS}`}>
      <h2 className="p-2 text-4xl text text-center font-bold mb-4">
        Bestiller
      </h2>
      <form className="mb-4">
        <div className="p-4 border border-solid rounded border-gray-400 grid grid-cols-12 gap-4">
          <div className="col-span-12 lg:col-span-6">
            <TextField
              InputLabelProps={{ shrink: true }}
              fullWidth
              required
              id="firstname"
              placeholder="Indtast dit fornavn her"
              label="Fornavn"
              variant="outlined"
              type="text"
              name="firstname"
              value={customerNameFirst}
              onChange={(e) => {
                setCustomerNameFirst(e.target.value);
              }}
            />
          </div>
          <div className="col-span-12 lg:col-span-6">
            <TextField
              InputLabelProps={{ shrink: true }}
              fullWidth
              required
              id="lastname"
              placeholder="Indtast dit efternavn her"
              label="Efternavn"
              variant="outlined"
              type="text"
              name="lastname"
              value={customerNameLast}
              onChange={(e) => {
                setCustomerNameLast(e.target.value);
              }}
            />
          </div>
          <div className="col-span-12 lg:col-span-6">
            <TextField
              InputLabelProps={{ shrink: true }}
              fullWidth
              required
              id="email"
              label="Email"
              placeholder="Indtast din email her"
              variant="outlined"
              type="email"
              name="email"
              value={customerEmail}
              onChange={(e) => {
                setCustomerEmail(e.target.value);
              }}
            />
          </div>
          <div className="col-span-12 lg:col-span-6">
            <TextField
              InputLabelProps={{ shrink: true }}
              fullWidth
              required
              id="phone"
              label="Telefonnummer"
              placeholder="Indtast dit telefonnummer her"
              variant="outlined"
              type="text"
              name="phone"
              value={customerPhone}
              onChange={(e) => {
                setCustomerPhone(e.target.value);
              }}
            />
          </div>
          <div className="col-span-12 lg:col-span-6">
            <TextField
              InputLabelProps={{ shrink: true }}
              fullWidth
              required
              id="address1"
              label="Adresse Linie 1"
              placeholder="Indtast din adresse her"
              variant="outlined"
              type="text"
              name="address1"
              value={customerAddress1}
              onChange={(e) => {
                setCustomerAddress1(e.target.value);
              }}
            />
          </div>
          <div className="col-span-12 lg:col-span-6">
            <TextField
              InputLabelProps={{ shrink: true }}
              fullWidth
              id="address2"
              label="Adresse Linie 2"
              placeholder="Indtast evt. yderligere adddresse her"
              variant="outlined"
              type="text"
              name="address2"
              value={customerAddress2}
              onChange={(e) => {
                setCustomerAddress2(e.target.value);
              }}
            />
          </div>
          <div className="col-span-12 lg:col-span-6">
            <TextField
              InputLabelProps={{ shrink: true }}
              fullWidth
              required
              id="zip"
              label="Postnummer"
              placeholder="Indtast dit postnummer her"
              variant="outlined"
              type="text"
              name="zip"
              value={customerZip}
              onChange={(e) => {
                setCustomerZip(e.target.value);
              }}
            />
          </div>
          <div className="col-span-12 lg:col-span-6 ">
            <TextField
              InputLabelProps={{ shrink: true }}
              fullWidth
              required
              id="city"
              label="By"
              placeholder="Indtast din by her"
              variant="outlined"
              type="text"
              name="city"
              value={customerCity}
              onChange={(e) => {
                setCustomerCity(e.target.value);
              }}
            />
          </div>
          <div className="col-span-12 md:col-span-6 text-sm">
            <span>
              Jeg accepterer{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="/rejsebetingelser-skirejser/"
              >
                rejsebestemmelser
              </a>
            </span>
            <Checkbox checked={termsChecked} onChange={handle_termsChange} />
          </div>
          <div className="col-span-12 md:col-span-6 md:text-right text-sm">
            <span>
              Jeg accepterer{" "}
              <a target="_blank" rel="noreferrer" href="/privatlivspolitik/">
                persondatapolitik
              </a>
            </span>
            <Checkbox
              label="Privacy terms"
              checked={privacyChecked}
              onChange={handle_privacyChange}
            />
          </div>
        </div>
      </form>

      <div className="flex justify-between">
        <Button
          onClick={() => {
            history.goBack();
          }}
          variant="outlined"
          size="large"
        >
          Tilbage
        </Button>
        <Button
          onClick={() => {
            handleCustomerFinalize();
          }}
          color="primary"
          size="large"
          disabled={
            customerStatus && privacyChecked && termsChecked ? false : true
          }
          variant="contained"
        >
          Bekræft
        </Button>
      </div>
    </div>
  );
};

export default OrderAccountHolderForm;

import React, { useContext } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import globalContext from "../../context/global/globalContext";
import { SET_CURRENT_DURATION } from "../../context/types";
import AccessTimeOutlinedIcon from "@material-ui/icons/AccessTimeOutlined";
import { BRAND_ID } from "../../constants";

const DurationsSelect = () => {
  const { durations, currentDuration, dispatch } = useContext(globalContext);

  return (
    <FormControl
      disabled={durations ? false : true}
      variant="outlined"
      className="col-span-6 lg:col-span-1"
    >
      <InputLabel shrink={true} id="DurationsSelect">
        {!currentDuration && (
          <AccessTimeOutlinedIcon
            style={{
              //color: BRAND_ID === 5 ? "#78a7c8" : "#0e6c56",
              color: BRAND_ID === 5 ? "#01a6c6" : "#0e6c56",
              padding: "0",
              margin: "0 2px 0 0",
              fontSize: "14px",
              verticalAlign: "top",
              fontWeight: "700",
            }}
          />
        )}
        Rejselængde
      </InputLabel>
      <Select
        id="DurationsSelect"
        value={currentDuration ? currentDuration : "placeholder"}
        label="DurationsSelect"
        className="mb-5 lg:mb-0 mr-0 lg:mr-2"
        onChange={(e) => {
          dispatch({
            type: SET_CURRENT_DURATION,
            payload: e.target.value,
          });
        }}
      >
        <MenuItem disabled value="placeholder">
          {durations ? "Vælg" : ""}
        </MenuItem>
        {durations?.map((duration, index) => (
          <MenuItem key={index} value={duration.days}>
            {duration.days} dage
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DurationsSelect;

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";
import OrderAccountHolder from "./pages/orderaccountholder";
import theme from "./styles/theme.js";
import Home from "./pages/Home";
import TripDetails from "./pages/TripDetails";
import Specials from "./pages/Specials";
import Order from "./pages/order";
import CreateOrder from "./pages/CreateOrder";
import OrderConfirmation from "./pages/orderconfirmation";
import React, { useContext, useState } from "react";
import globalContext from "./context/global/globalContext";
import axios from "axios";
import DownhillSkiingOutlinedIcon from "@mui/icons-material/DownhillSkiingOutlined";
import BeachAccessOutlinedIcon from "@mui/icons-material/BeachAccessOutlined";

import { ToggleButton } from "@material-ui/lab";
import { ToggleButtonGroup } from "@material-ui/lab";

import { BRAND_ID } from "./constants.js";

import {
  SET_CURRENT_SUB_SEASON,
  SET_CURRENT_DURATION,
  SET_CURRENT_TRANSPORT,
  SET_CURRENT_DATE,
} from "./context/types";
import Loading from "./components/Loading.js";

const App = () => {
  const { trips, order, currentSubSeason, dispatch } =
    useContext(globalContext);

  const handleSubSeasonChange = (event, newSeason) => {
    dispatch({
      type: SET_CURRENT_SUB_SEASON,
      payload: newSeason,
    });
    dispatch({
      type: SET_CURRENT_DURATION,
      payload: null,
    });
    dispatch({
      type: SET_CURRENT_TRANSPORT,
      payload: null,
    });
    dispatch({
      type: SET_CURRENT_DATE,
      payload: null,
    });
  };

  const [loading, setLoading] = useState(false);

  axios.interceptors.request.use(
    function (config) {
      setLoading(true);
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    function (response) {
      setLoading(false);
      return response;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
  return (
    <ThemeProvider theme={theme}>
      <main>
        <div
          className={`
            ${
              order || trips || BRAND_ID === 5
                ? "booking-outer trip-outer-trips"
                : BRAND_ID === 18
                ? "booking-outer trip-outer-home booking-outer-vhservice"
                : "booking-outer trip-outer-home"
            } trip-outer-season-${currentSubSeason}
          `}
        >
          <Loading
            loading={loading}
            color={BRAND_ID === 5 ? "#01a6c6" : "#0e6c56"}
          />

          {!trips && (
            <div className="trip-search-season text-center mb-4">
              <ToggleButtonGroup
                color="primary"
                value={currentSubSeason}
                exclusive
                onChange={handleSubSeasonChange}
              >
                <ToggleButton
                  style={{
                    fontSize: "14px",
                    letterSpacing: "1px",
                    fontWeight: "400",
                  }}
                  value="winter"
                >
                  <DownhillSkiingOutlinedIcon
                    style={{ fontSize: "16px", marginRight: "5px" }}
                  />
                  Vinter
                </ToggleButton>
                <ToggleButton
                  style={{
                    fontSize: "14px",
                    letterSpacing: "1px",
                    fontWeight: "400",
                  }}
                  value="summer"
                >
                  <BeachAccessOutlinedIcon
                    style={{ fontSize: "16px", marginRight: "5px" }}
                  />
                  Sommer
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          )}
          <div
            className="booking-outer-wrap booking-container p-4 rounded "
            style={
              loading
                ? {
                    position: "relative",
                    transition: "all 350ms ease-in-out",
                    opacity: "0.6",
                    pointerEvents: "none",
                    filter: "blur(5px)",
                  }
                : {
                    position: "relative",
                    transition: "all 150ms ease-in-out",
                    opacity: "1.0",
                    pointerEvents: "inherit",
                    filter: "blur(0px)",
                  }
            }
          >
            <Router>
              <Switch>
                <Route exact path="/order/create">
                  <CreateOrder />
                </Route>
                <Route exact path="/order">
                  <Order />
                </Route>
                <Route path="/orderdetails">
                  <OrderAccountHolder />
                </Route>
                <Route path="/order/payment">
                  <OrderConfirmation />
                </Route>
                <Route path="/orderconfirmation">
                  <OrderConfirmation />
                </Route>
                <Route path="/orderpaymentconfirmation">
                  <OrderConfirmation />
                </Route>
                <Route path="/orderpaymentcallback">
                  <OrderConfirmation />
                </Route>
                <Route path="/orderpaymentcancel">
                  <OrderConfirmation />
                </Route>

                <Route path="/tripdetails">
                  <TripDetails />
                </Route>
                <Route path="/restpladser">
                  <Specials special_type="lastminute" />
                </Route>
                <Route path="/afbudsrejser-skiferie">
                  <Specials special_type="lastminute" />
                </Route>
                <Route path="/tilbud*">
                  <Specials special_type="offers" />
                </Route>
                <Route path="/gruppe-react">
                  <Home />
                </Route>
                <Route exact path="*/">
                  <Home />
                </Route>
              </Switch>
            </Router>
          </div>
        </div>
      </main>
    </ThemeProvider>
  );
};

export default App;

import { useEffect, useContext, useMemo, useCallback } from "react";
import { useLocation } from "react-router-dom";
import globalContext from "../context/global/globalContext";
import Details from "../components/Details";
import DetailsUcpa from "../components/DetailsUcpa";
import axios from "axios";
import {
  API_URL,
  API_ENDPOINT_STRING,
  PAX_DEFAULT_STRING,
  BRAND_ID,
} from "../constants";

import {
  SET_CURRENT_COMBINATIONS,
  SET_TRIPS,
  SET_CURRENT_TRIP,
} from "../context/types";

const TripDetails = () => {
  const heySearch = useLocation().search;
  const query = useMemo(() => new URLSearchParams(heySearch), [heySearch]);

  const { token, destinations, dispatch, trips, currentTrip } =
    useContext(globalContext);

  const getCombinations = useCallback(() => {
    let travelDate = new Date(query.get("date")).getTime();
    let added_hours = 12 * 60 * 60 * 1000;

    travelDate = travelDate + added_hours;

    axios
      .get(
        `${API_URL}/wp-json/${API_ENDPOINT_STRING}/v1/trips?destination_id=${query.get(
          "destination"
        )}&ages=${
          query.get("ages") ? query.get("ages") : PAX_DEFAULT_STRING
        }&duration=${query.get(
          "duration"
        )}&date=${travelDate}&transport=transport_${query.get(
          "transport"
        )}&period_id=${query.get(
          "period"
        )}&brand_id=${BRAND_ID}&token=${token}}`
      )
      .then(({ data }) => {
        if (data.result) {
          data.result.map(
            (trip) =>
              trip.accomodation_code === query.get("accommodation") &&
              dispatch({
                type: SET_CURRENT_TRIP,
                payload: trip,
              })
          );
          dispatch({
            type: SET_TRIPS,
            payload: data.result,
          });
        }
      });

    axios
      .get(
        `${API_URL}/wp-json/${API_ENDPOINT_STRING}/v1/trips/combinations?&ages=${
          query.get("ages") ? query.get("ages") : PAX_DEFAULT_STRING
        }&duration=${query.get(
          "duration"
        )}&date=${travelDate}&transport=transport_${query.get(
          "transport"
        )}&token=${token}&accomodation_code=${query.get(
          "accommodation"
        )}&period_id=${query.get("period")}&brand_id=${BRAND_ID}`
      )
      .then((data) => {
        dispatch({
          type: SET_CURRENT_COMBINATIONS,
          payload: data.data.result,
        });
      });
  }, [dispatch, query, token]);

  useEffect(() => {
    if (token) {
      getCombinations();
    }
  }, [getCombinations, token]);

  return (
    <>
      {destinations && (
        <div className="trip-search-trips">
          {currentTrip && (
            <div id="trip">
              {BRAND_ID === "5" ? (
                <Details singular={true} />
              ) : (
                <DetailsUcpa singular={true} />
              )}
            </div>
          )}

          {trips === undefined && (
            <div className="m-4 p-3 text-center booking-container">
              <p className="">
                Har du spørgsmål kan du kontakte os på{" "}
                <a href="tel:70100010">70 10 00 10</a>
              </p>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default TripDetails;

import { Button, FormControl } from "@material-ui/core";
import { scroller } from "react-scroll";
import { useEffect, useContext, useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import globalContext from "../context/global/globalContext";
import DatesSelect from "../components/form-inputs/DatesSelect";
import DestinationsSelect from "../components/form-inputs/DestinationsSelect";
import DurationsSelect from "../components/form-inputs/DurationsSelect";
import TransportsSelect from "../components/form-inputs/TransportsSelect";
import SortTripsMenu from "../components/trip-sorting/SortTripsMenu";
import Trip from "../components/Trip";
import Details from "../components/Details";
import DetailsUcpa from "../components/DetailsUcpa";
import DetailsUcpaGrid from "../components/DetailsUcpaGrid";
import AdultsSelect from "../components/form-inputs/AdultsSelect";
import ChildrenSelect from "../components/form-inputs/ChildrenSelect";
import {
  SET_CURRENT_DATE,
  SET_CURRENT_DURATION,
  SET_CURRENT_TRANSPORT,
  SET_ADULTS,
  SET_CHILDREN,
  SET_CHILDREN_AGES,
  SET_CURRENT_DESTINATION,
  SET_CURRENT_COMBINATIONS,
  SET_CURRENT_TRIP,
  SET_CURRENT_HOTEL,
  SET_CURRENT_SUB_SEASON,
} from "../context/types";

import { tripSorting } from "../components/trip-sorting/useTripSorting";
import { SortTypes } from "../components/trip-sorting/Sorts";
import { BRAND_ID } from "../constants";

const Home = () => {
  const {
    dispatch,
    destinations,
    durations,
    dates,
    transports,
    handleSubmit,
    children,
    adults,
    childrenAges,
    currentDestination,
    currentSubSeason,
    currentDuration,
    currentTransport,
    currentDate,
    trips,
    currentTrip,
    gettingTrips,
    gotTrips,
  } = useContext(globalContext);

  const history = useHistory();

  useEffect(() => {
    if (trips && BRAND_ID !== 5) {
      scroller.scrollTo("trips", {
        duration: 1200,
        offset: -350,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    }
  }, [trips]);

  const overwrite_destination = window.hey_destination_id
    ? window.hey_destination_id
    : false;

  const heySearch = useLocation().search;
  const query = useMemo(() => new URLSearchParams(heySearch), [heySearch]);

  let overwrite_query_destination_id = window.hey_destination_id
    ? window.hey_destination_id
    : query.get("destination_id");

  let overwrite_query_single = overwrite_destination
    ? false
    : query.get("single");
  let overwrite_query_ages = overwrite_destination ? "30" : query.get("ages");
  let overwrite_query_duration = overwrite_destination
    ? false
    : query.get("duration");
  let overwrite_query_transport = overwrite_destination
    ? false
    : query.get("transport");
  let overwrite_query_date =
    overwrite_destination && dates?.length > 0 && dates[0].date
      ? false
      : query.get("date")
      ? query.get("date")
      : currentDate;
  let overwrite_query_subseason = overwrite_destination
    ? false
    : query.get("subseason");

  useEffect(() => {
    if (
      history &&
      dates &&
      overwrite_query_destination_id &&
      overwrite_query_ages &&
      overwrite_query_duration &&
      overwrite_query_transport &&
      overwrite_query_date &&
      overwrite_query_subseason
    ) {
      history.push(
        `?destination_id=${overwrite_query_destination_id}&ages=${overwrite_query_ages}&duration=${overwrite_query_duration}&date=${overwrite_query_date}&subseason=${overwrite_query_subseason}&transport=${overwrite_query_transport}`
      );
    }
  }, [
    history,
    dates,
    overwrite_query_destination_id,
    overwrite_query_ages,
    overwrite_query_duration,
    overwrite_query_transport,
    overwrite_query_subseason,
    overwrite_query_date,
  ]);

  let query_destination_id = overwrite_destination
    ? overwrite_destination
    : query.get("destination_id");

  let query_single = overwrite_destination
    ? overwrite_query_single
    : query.get("single");
  let query_ages = overwrite_destination
    ? overwrite_query_ages
    : query.get("ages");
  let query_duration = overwrite_destination
    ? overwrite_query_duration
    : query.get("duration");
  let query_transport = overwrite_destination
    ? overwrite_query_transport
    : query.get("transport");
  let query_date = overwrite_destination
    ? overwrite_query_date
    : query.get("date");
  let query_subseason = overwrite_destination
    ? overwrite_query_subseason
    : query.get("subseason");

  useEffect(() => {
    let childrenSet = false;
    let adultsSet = false;
    if (query_ages) {
      let query_adult_count = 0;
      let query_children_count = 0;
      let query_children_values = [];
      let query_all_ages = query_ages.split(",");
      query_all_ages.forEach((age, index) => {
        if (age >= 17) {
          query_adult_count++;
        } else {
          query_children_count++;
          query_children_values.push(age);
        }
      });

      dispatch({
        type: SET_ADULTS,
        payload: adults !== 2 ? adults : query_adult_count,
      });
      dispatch({
        type: SET_CHILDREN,
        payload: children !== 0 ? children : query_children_count,
      });
      dispatch({
        type: SET_CHILDREN_AGES,
        payload:
          childrenAges?.length > 0 ? childrenAges : query_children_values,
      });
      childrenSet = true;
      adultsSet = true;
    }
    let destination_object = {};
    if (destinations && query_destination_id && !currentDestination) {
      destinations.forEach((destination_country, country_index) => {
        destination_country.destinations.forEach((destination, index) => {
          if (destination.code === query_destination_id) {
            destination_object = destination;
          }
          dispatch({
            type: SET_CURRENT_DESTINATION,
            payload: destination_object,
          });
        });
      });
    }
    if (durations && query_duration) {
      dispatch({
        type: SET_CURRENT_DURATION,
        payload: currentDuration ? currentDuration : query_duration,
      });
    }
    if (transports && query_transport) {
      dispatch({
        type: SET_CURRENT_TRANSPORT,
        payload: currentTransport ? currentTransport : query_transport,
      });
    }
    if (dates && query_date) {
      let query_date_object = parseInt(query_date);

      dispatch({
        type: SET_CURRENT_DATE,
        payload: currentDate ? currentDate : query_date_object,
      });
    }
    if (query_subseason) {
      dispatch({
        type: SET_CURRENT_SUB_SEASON,
        payload: query_subseason ? query_subseason : currentSubSeason,
      });
    }

    if (
      currentDestination &&
      currentDuration &&
      currentTransport &&
      currentDate &&
      currentSubSeason &&
      adultsSet &&
      childrenSet
    ) {
      const countAdults = (number) => {
        let countAdults = [];
        for (let i = 0; i < adults; i++) {
          countAdults.push("30");
        }
        return countAdults;
      };
      const childrenFiltered = children
        ? childrenAges.filter(function (el) {
            return el != null;
          })
        : [];

      const adultsFiltered = countAdults(adults);
      const allAges =
        childrenFiltered && childrenFiltered.length
          ? adultsFiltered + "," + childrenFiltered.slice(0, children)
          : adultsFiltered;
      if (history.action === "PUSH") {
        history.push();
        dispatch({
          type: SET_CURRENT_COMBINATIONS,
          payload: false,
        });
        dispatch({
          type: SET_CURRENT_TRIP,
          payload: false,
        });
        dispatch({
          type: SET_CURRENT_HOTEL,
          payload: false,
        });
        history.push(
          `?destination_id=${currentDestination.code}&ages=${allAges}&duration=${currentDuration}&date=${currentDate}&subseason=${currentSubSeason}&transport=${currentTransport}`
        );
      }
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    transports,
    destinations,
    durations,
    query_destination_id,
    query_ages,
    query_duration,
    query_transport,
    query_date,
  ]);

  const doHandleSubmit = (event) => {
    event.preventDefault();

    dispatch({
      type: SET_CURRENT_COMBINATIONS,
      payload: false,
    });

    dispatch({
      type: SET_CURRENT_TRIP,
      payload: false,
    });

    const countAdults = (number) => {
      let countAdults = [];
      for (let i = 0; i < adults; i++) {
        countAdults.push("30");
      }
      return countAdults;
    };

    const childrenFiltered = children
      ? childrenAges.filter(function (el) {
          return el != null;
        })
      : [];

    const adultsFiltered = countAdults(adults);

    const allAges =
      childrenFiltered && childrenFiltered.length
        ? adultsFiltered + "," + childrenFiltered.slice(0, children)
        : adultsFiltered;

    history.push(
      `?destination_id=${currentDestination.code}&ages=${allAges}&duration=${currentDuration}&date=${currentDate}&transport=${currentTransport}&subseason=${currentSubSeason}`
    );
    handleSubmit();
  };

  const tripsFound = !trips || (trips && trips.length === 0) ? false : true;

  useEffect(() => {
    if (currentTrip && BRAND_ID !== 5) {
      scroller.scrollTo("trip", {
        duration: 1200,
        offset: -150,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    }
  }, [currentTrip]);

  const [sortType, setSortType] = useState(SortTypes.ASC);
  const tripSorter = useMemo(() => tripSorting(sortType), [sortType]);
  const sortedTrips = useMemo(
    () => trips?.sort(tripSorter),
    [trips, tripSorter]
  );

  return (
    <>
      {destinations && (
        <div className={trips ? "trip-search-trips" : "trip-search-home"}>
          <div className="trip-search-form">
            <form
              onSubmit={doHandleSubmit}
              className={`${
                BRAND_ID === 5 ? "lg:grid-cols-6" : "lg:grid-cols-7"
              } grid grid-cols-12 md:grid-cols-3 gap-x-2 lg:gap-x-0 justify-center`}
            >
              <DestinationsSelect />
              <DurationsSelect />
              <TransportsSelect />
              <DatesSelect />
              <AdultsSelect />
              {BRAND_ID !== 5 && <ChildrenSelect />}
              <FormControl className="col-span-12 lg:col-span-1">
                <Button
                  disabled={
                    currentDestination &&
                    currentDuration &&
                    currentTransport &&
                    currentDate
                      ? false
                      : true
                  }
                  size="large"
                  color="primary"
                  type="submit"
                  variant="contained"
                  style={{ minHeight: "56px" }}
                >
                  Find Rejse
                </Button>
              </FormControl>
            </form>
          </div>

          {currentTrip &&
            currentTrip.period_id &&
            BRAND_ID &&
            BRAND_ID !== 5 && (
              <div id="trip">
                <Details />
              </div>
            )}
          {currentTrip &&
            currentTrip.period_id &&
            BRAND_ID &&
            BRAND_ID === 5 && (
              <div id="trip">
                {query_single ? <DetailsUcpa /> : <DetailsUcpaGrid />}
              </div>
            )}

          {tripsFound && (
            <div>
              <div className="flex flex-row">
                <div className="flex-grow"></div>
                <SortTripsMenu setSort={setSortType} sort={sortType} />
              </div>
              <div id="trips">
                {sortedTrips.map(
                  (trip, index) =>
                    (!currentTrip ||
                      currentTrip.accomodation_code !==
                        trip.accomodation_code) && (
                      <Trip key={index} trip={trip} />
                    )
                )}
              </div>
            </div>
          )}
          {!tripsFound && !gettingTrips && gotTrips && (
            <div
              id="trips"
              className="relative mt-10 mb-2 p-6 border text-center border-solid border-1 border-gray-300 rounded bg-gray-100"
            >
              <h6 className="font-bold mb-4">
                Der er desværre ingen rejser der passer til din søgning
              </h6>
              <p>
                Du kan altid kontakte os på{" "}
                <a href="tel:70100010">70 10 00 10</a> så vi kan finde en rejse
                til dig
              </p>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Home;

// import { Link } from "react-router-dom";
import React, { useContext, useEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import CarouselComponent from "./CarouselComponent";
import HotelMetaComponent from "./HotelMetaComponent";
import HotelPreviewComponent from "./HotelPreviewComponent";
import HotelPriceComponent from "./HotelPriceComponent";
import HotelTravelDetailsComponent from "./HotelTravelDetailsComponent";
import {
  SET_CURRENT_TRIP,
  SET_CURRENT_COMBINATIONS,
  SET_CURRENT_HOTEL,
  SET_CURRENT_DATE,
  SET_CURRENT_DURATION,
  SET_CURRENT_TRANSPORT,
  SET_ADULTS,
  SET_CHILDREN,
  SET_CHILDREN_AGES,
} from "../context/types";
import globalContext from "../context/global/globalContext";
import { Button } from "@material-ui/core";

const TripSpecial = ({ trip }) => {
  const { dispatch } = useContext(globalContext);
  const {
    destination_name,
    room_description,
    minimum_price,
    post,
    // accomodation_code,
    // period_id,
  } = trip;

  const history = useHistory();

  const heySearch = useLocation().search;
  const query = useMemo(() => new URLSearchParams(heySearch), [heySearch]);

  let query_accomodation = query.get("accomodation");
  let query_period_id = query.get("period_id");
  let query_transport = query.get("transport");

  useEffect(() => {
    if (
      query_accomodation &&
      query_period_id &&
      query_transport &&
      query_accomodation === trip.accomodation_code &&
      query_transport === "transport_" + trip.transport_code &&
      trip.period_id === parseInt(query_period_id)
    ) {
      dispatch({
        type: SET_CURRENT_TRIP,
        payload: trip,
      });
      dispatch({
        type: SET_CURRENT_COMBINATIONS,
        payload: false,
      });
      dispatch({
        type: SET_CURRENT_HOTEL,
        payload: false,
      });
    }
  }, [trip, query_accomodation, query_period_id, query_transport, dispatch]);

  const setAges = (agestring) => {
    let query_adult_count = 0;
    let query_children_count = 0;
    let query_children_values = [];
    let query_all_ages = agestring.split(",");
    query_all_ages.forEach((age, index) => {
      if (age >= 17) {
        query_adult_count++;
      } else {
        query_children_count++;
        query_children_values.push(age);
      }
    });

    dispatch({
      type: SET_ADULTS,
      payload: query_adult_count,
    });
    dispatch({
      type: SET_CHILDREN,
      payload: query_children_count,
    });
    dispatch({
      type: SET_CHILDREN_AGES,
      payload: query_children_values,
    });
  };

  return (
    <div className="relative mt-10 mb-2 p-6 border border-solid border-1 border-gray-300 rounded bg-gray-100">
      <div className="grid md:grid-cols-12 grid-cols-1">
        <div className="col-span-12 lg:col-span-5 lg:hidden">
          <>
            {post?.meta?.gallery_settings && (
              <CarouselComponent
                className="rounded overflow-hidden mb-0"
                height="h-56 lg:h-72"
                images={post.meta.gallery_settings}
              />
            )}
          </>
        </div>
        <div className="col-span-12 lg:col-span-7 pr-0 lg:pr-6">
          {post && destination_name && room_description && minimum_price && (
            <HotelPreviewComponent
              trip={trip}
              post={post}
              destination_name={destination_name}
              room_description={room_description}
              minimum_price={minimum_price}
              special={true}
            />
          )}
          <h4 className="text-center lg:text-left text-sm mb-1 font-black">
            Detaljer
          </h4>

          {post?.meta && <HotelMetaComponent trip={trip} post={post} />}

          <h4 className="text-center lg:text-left text-sm mb-1 font-black">
            Rejseinformation
          </h4>

          {post?.meta && <HotelTravelDetailsComponent trip={trip} />}
        </div>
        <div className="col-span-12 lg:col-span-5 mb-6 lg:mb-0">
          <>
            {post?.meta?.gallery_settings && (
              <CarouselComponent
                className="rounded overflow-hidden mb-4 hidden lg:block"
                height="h-56 lg:h-72"
                images={post.meta.gallery_settings}
              />
            )}
            {post && destination_name && room_description && minimum_price && (
              <HotelPriceComponent
                className="mt-4 lg:mt-0"
                post={post}
                trip={trip}
                destination_name={destination_name}
                room_description={room_description}
                minimum_price={minimum_price}
                special={true}
              />
            )}
            <div className="col-span-12 mt-4">
              <Button
                className="w-full"
                color="primary"
                variant="contained"
                size="large"
                onClick={() => {
                  let departure_date = new Date(
                    trip.departure_date.replace("-", ".")
                  );

                  departure_date.setHours(12, 0, 0);

                  setAges(trip.age_string);

                  dispatch({
                    type: SET_CURRENT_DURATION,
                    payload: trip.travel_length,
                  });
                  dispatch({
                    type: SET_CURRENT_TRANSPORT,
                    payload: "transport_" + trip.transport_code,
                  });
                  dispatch({
                    type: SET_CURRENT_DATE,
                    payload: departure_date.getTime(),
                  });
                  dispatch({
                    type: SET_CURRENT_TRIP,
                    payload: trip,
                  });
                  dispatch({
                    type: SET_CURRENT_COMBINATIONS,
                    payload: false,
                  });
                  dispatch({
                    type: SET_CURRENT_HOTEL,
                    payload: false,
                  });
                  history.push(
                    `?destination_id=${trip.destination_code}&ages=${
                      trip.age_string
                    }&duration=${
                      trip.travel_length
                    }&date=${departure_date.getTime()}&transport=transport_${
                      trip.transport_code
                    }&accomodation=${trip.accomodation_code}&period_id=${
                      trip.period_id
                    }`
                  );
                }}
              >
                Se Hotel & Priser
              </Button>
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

export default TripSpecial;

// <Link to="/hotel/details" className="p-4 flex flex-col space-y-6 "></Link>

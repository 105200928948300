import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { scroller } from "react-scroll";
import { useLocation } from "react-router-dom";
import globalContext from "../context/global/globalContext";
import axios from "axios";
import { Button } from "@material-ui/core";
import { API_URL, API_ENDPOINT_STRING } from "../constants";

import { SET_CURRENT_SUB_SEASON } from "../context/types";

const OrderConfirmationForm = ({ tailwindCSS }) => {
  const {
    dispatch,
    order,
    orderHttpStatus,
    orderRequestStatus,
    token,
    fetchOrder,
  } = useContext(globalContext);

  const [orderFetched, setOrderFetched] = useState(false);

  const heySearch = useLocation().search;
  const query = useMemo(() => new URLSearchParams(heySearch), [heySearch]);

  // Transport departure description for participant dependant on transport type
  const departureInfo = useCallback(
    (participantItem) =>
      order.transport_code === 2
        ? participantItem.departure_airport_code_for_departure +
          " " +
          participantItem.departure_airport_for_departure +
          " -> " +
          participantItem.arrival_airport_code_for_departure +
          " " +
          participantItem.arrival_airport_for_departure +
          " (" +
          participantItem.transport_unit_for_departure +
          ")"
        : participantItem.location_for_departure,
    [order]
  );

  // Transport return description for participant dependant on transport type
  const returnInfo = useCallback(
    (participantItem) =>
      order.transport_code === 2
        ? participantItem.departure_airport_code_for_return +
          " " +
          participantItem.departure_airport_for_return +
          " -> " +
          participantItem.arrival_airport_code_for_return +
          " " +
          participantItem.arrival_airport_for_return +
          " (" +
          participantItem.transport_unit_for_return +
          ")"
        : participantItem.location_for_return,
    [order]
  );

  var formatter = new Intl.NumberFormat("da-DK", {
    style: "currency",
    currency: "DKK",
  });

  useEffect(() => {
    scroller.scrollTo("orderaccountconfirmationform", {
      duration: 1200,
      offset: -350,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  }, []);

  useEffect(() => {
    if (!orderFetched && token && query) {
      let orderid = query.get("order_no");
      if (!orderid) orderid = query.get("orderid");

      let pincode = query.get("pin_code");
      if (!pincode) pincode = query.get("pincode");

      let subseason = query.get("subseason");
      if (subseason) {
        dispatch({
          type: SET_CURRENT_SUB_SEASON,
          payload: subseason,
        });
      }

      fetchOrder(orderid, pincode);
      setOrderFetched(true);
    }
  }, [token, query, fetchOrder, orderFetched, dispatch]);

  /**
   * Finalizes an order by changing it to booking from quote
   */
  const finalizeOrder = useCallback(() => {
    if (order && order.order_status === "Quote") {
      const postDataConfirm = {
        token: token,
        order_id: order.id,
        pincode: order.pin_code,
      };

      axios
        .post(
          `${API_URL}/wp-json/${API_ENDPOINT_STRING}/v1/orders/finalize`,
          postDataConfirm
        )
        .then(() => {
          window.location.reload();
        });
    }
  }, [order, token]);

  return (
    <div id="orderaccountconfirmationform" className={` ${tailwindCSS}`}>
      {orderHttpStatus && !query.get("status") && query.get("pincode") && (
        <>
          <h2 className="p-2 text-3xl text-center font-black mb-2">
            Rejsedetaljer
          </h2>
          {orderRequestStatus && (
            <p className="text-red-600 text-center">
              Tak for din bestilling. Da denne rejse er kun til rådighed via
              forespørgsel, vil du hurtigst muligt blive kontaktet af en af
              vores rejsekonsulenter, så vi kan få gennemført din bestilling
            </p>
          )}
          {!orderRequestStatus && (
            <p className="text-center">
              Tak for at du rejser med os, vi håber du glæder dig. Nedenfor kan
              du se detaljerne for din rejse, samt gennemføre hel eller delvis
              betaling
            </p>
          )}
        </>
      )}
      {orderHttpStatus && !query.get("status") && !query.get("pincode") && (
        <>
          <h2 className="p-2 text-3xl text-center font-black mb-2">
            Bekræftelse
          </h2>
          <p className="text-center">
            Tak for din bestilling. Du vil modtage en email hurtigst muligt med
            yderligere information
          </p>
        </>
      )}
      {orderHttpStatus && query.get("status") === "confirm" && (
        <>
          <h2 className="p-2 text-3xl text-center font-black mb-2">
            Tak for din betaling
          </h2>
          <p className="text-center">
            Tak for din indbetaling. Vi har registreret følgende på din rejse
          </p>
        </>
      )}
      {orderHttpStatus && query.get("status") === "cancel" && (
        <>
          <h2 className="p-2 text-3xl text-center font-black mb-2">
            Du har annulleret din betaling
          </h2>
          <p className="text-center">
            Gik der noget galt? Du kan prøve igen ved at klikke på et
            betalingslink i bunden af denne side.
          </p>
        </>
      )}
      {orderHttpStatus && !order?.customer && (
        <>
          <h2 className="p-2 text-3xl text-center font-black mb-2">
            Dit tilbud er udløbet
          </h2>
          <p className="text-center">
            Du kan desværre ikke længere benytte dit tilbud, kontakt venligst
            vores salgsafdelling
          </p>
        </>
      )}

      {order?.customer?.first_name && (
        <>
          <h4 className="p-2 text-xl text-center font-bold mb-2">Betaling</h4>
          <div className="grid grid-cols-2 gap-4 mb-4 p-4 border border-solid rounded border-gray-400 text-xs md:text-sm">
            <div className="col font-bold">Registreret indbetalt totalt</div>
            <div className="col font-semibold">
              {order?.booking_amount_paid &&
                formatter.format(order.booking_amount_paid)}
            </div>
            <div className="col font-bold">
              Resterende beløb til indbetaling
            </div>
            <div className="col font-semibold">
              {order?.booking_amount_remaining &&
                formatter.format(order?.booking_amount_remaining)}
            </div>
          </div>
        </>
      )}

      {order?.customer?.first_name && (
        <div>
          <h4 className="p-2 text-xl text-center font-bold mb-2">Kunde</h4>
          <div className="grid grid-cols-12 mb-4 p-4 border border-solid rounded border-gray-400 text-xs md:text-sm">
            <div className="col-span-3 font-bold">Navn</div>
            <div className="col-span-9">
              {order?.customer?.first_name} {order?.customer?.last_name}
            </div>
            <div className="col-span-3 font-bold">Adresse</div>
            <div className="col-span-9">{order?.customer?.address}</div>
            <div className="col-span-3 font-bold">By</div>
            <div className="col-span-9">{order?.customer?.city}</div>
            <div className="col-span-3 font-bold">Postnr</div>
            <div className="col-span-9">{order?.customer?.postal_code}</div>
            <div className="col-span-3 font-bold">Land</div>
            <div className="col-span-9">{order?.customer?.country}</div>
            <div className="col-span-3 font-bold">Telefon</div>
            <div className="col-span-9">{order?.customer?.phone_number}</div>
            <div className="col-span-3 font-bold">Email</div>
            <div className="col-span-9">{order?.customer?.email_address}</div>
          </div>
          <div>
            <h4 className="p-2 text-center text-xl font-bold mb-2">
              Deltagere
            </h4>
            {order?.participants.map((participantItem, participantKey) => (
              <div
                className="mb-4 p-4 border border-solid rounded border-gray-400 text-xs md:text-sm"
                key={participantKey}
              >
                <div className="grid grid-cols-12 participant_content mb-4 md:md-0">
                  <div className="col-span-12 md:col-span-3 font-bold">
                    Navn
                  </div>
                  <div className="col-span-8 md:col-span-6 font-semibold">
                    {participantItem.full_name}
                  </div>
                  <div className="col-span-4 md:col-span-3 text-right font-light">
                    {participantItem.age} år -{" "}
                    {participantItem.gender === "M"
                      ? "Mand"
                      : participantItem.age < 17
                      ? "Barn"
                      : "Kvinde"}
                  </div>
                </div>
                {participantItem.services.map(
                  (serviceItem, serviceKey) =>
                    serviceItem.selected === 1 && (
                      <div
                        className="grid grid-cols-12 mb-4 md:md-0"
                        style={{ fontSize: "12px" }}
                        key={serviceKey}
                      >
                        <div className="col-span-12 md:col-span-3 font-bold">
                          {serviceItem.service_group}
                        </div>
                        <div className="col-span-8 md:col-span-6">
                          {serviceItem.description}
                        </div>
                        <div className="col-span-4 md:col-span-3 text-right">
                          {formatter.format(serviceItem.service_price)}
                        </div>
                      </div>
                    )
                )}
                {/* Show only when a transportation is apart of order e.g. plane or bus */}
                {order?.transport_code !== 0 &&
                  participantItem.location_for_departure && (
                    <div
                      className="grid grid-cols-12"
                      style={{ fontSize: "12px" }}
                    >
                      <div className="col-span-12 md:col-span-3 font-bold">
                        {order?.transport_code === 2
                          ? "Afrejse"
                          : "Afrejse opsamling"}
                      </div>
                      <div className="col-span-12 md:col-span-6">
                        {/* Dynamic transport description */}
                        {departureInfo(participantItem)}{" "}
                      </div>
                      <div className="col-span-12 md:col-span-3 md:text-right">
                        {formatter.format(
                          participantItem.location_for_departure_price
                        )}
                      </div>
                    </div>
                  )}{" "}
                {order?.transport_code !== 0 &&
                  participantItem.location_for_departure && (
                    <div
                      className="grid grid-cols-12"
                      style={{ fontSize: "12px" }}
                    >
                      <div className="col-span-12 md:col-span-3 font-bold">
                        {order.transport_code === 2
                          ? "Hjemrejse"
                          : "Hjemrejse afsætning"}
                      </div>
                      <div className="col-span-12 md:col-span-6">
                        {/* Dynamic transport description */}
                        {returnInfo(participantItem)}
                      </div>
                      <div className="col-span-12 md:col-span-3 md:text-right">
                        {formatter.format(
                          participantItem.location_for_return_price
                        )}
                      </div>
                    </div>
                  )}
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="grid grid-cols-12 gap-4">
        {orderHttpStatus && orderHttpStatus !== 200 && !orderRequestStatus && (
          <h3 className="col-span-12">
            Der er noget galt med din bestilling, kontakt os venligst
          </h3>
        )}

        {orderHttpStatus &&
          orderHttpStatus === 200 &&
          order &&
          order.order_status === "Quote" &&
          !orderRequestStatus && (
            <Button
              className="w-full col-span-6"
              variant="contained"
              color="primary"
              onClick={() => finalizeOrder()}
              value="Continue to payment..."
            >
              Godkend og gå til betaling
            </Button>
          )}

        {orderHttpStatus &&
          orderHttpStatus === 200 &&
          order &&
          order.order_status !== "Quote" &&
          order.order_status !== "Cancelled" &&
          order.order_status !== "Temporary" &&
          order.order_status !== "Rejected" &&
          !orderRequestStatus && (
            <div className="col-span-12 text-right">
              <Button
                className="w-full"
                variant="contained"
                color="primary"
                type="submit"
                href={order.pay_url}
                value="Continue to payment..."
                style={{ color: "white" }}
              >
                Betal
              </Button>
            </div>
          )}
      </div>
    </div>
  );
};

export default OrderConfirmationForm;

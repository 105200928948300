import React, { useEffect, useMemo, useReducer, useCallback } from "react";
import globalReducer from "./globalReducer";
import GlobalContext from "./globalContext";
import {
  SET_TOKEN,
  SET_DESTINATIONS,
  SET_ALL_DURATIONS,
  SET_COUNTRIES,
  SET_DURATIONS,
  SET_TRANSPORTS,
  SET_DATES,
  SET_TRIPS,
  SET_CURRENT_TRIP,
  SET_CURRENT_DATE,
  SET_CURRENT_COMBINATIONS,
  SET_CUSTOMER_DATA,
  SET_GETTING_TRIPS,
  SET_GOT_TRIPS,
  SET_CURRENT_HOTEL,
  SET_PARTICIPANTS_GLOBAL_DATA,
  SET_PARTICIPANTS_SAVED,
  SET_ORDER,
  SET_ORDER_HTTP_STATUS,
  SET_ORDER_REQUEST_STATUS,
} from "../types";
import axios from "axios";
import {
  API_URL,
  API_ENDPOINT_STRING,
  API_ENDPOINT_DOMAIN,
  PAX_DEFAULT,
  BRAND_ID,
} from "../../constants";
import Loading from "../../components/Loading";

const GlobalState = (props) => {
  const initialState = useMemo(
    () => ({
      token: "",
      destinations: [],
      allDurations: [],
      countries: null,
      currentDestination: "",
      durations: null,
      currentDuration: "",
      transports: null,
      currentTransport: "",
      adults: PAX_DEFAULT,
      children: 0,
      childrenAges: [],
      dates: [],
      currentDate: null,
      trips: null,
      gettingTrips: false,
      gotTrips: false,
      currentTrip: null,
      currentDestinations: false,
      currentSubSeason: "winter",
      currentHotel: null,
      currentCombinations: [],
      participantsData: [],
      participant_service_group_id: "",
      participant_service_price_id: "",
      participantsGlobalData: [],
      participantsSaved: false,
      customerData: {},
      order: null,
      orderHttpStatus: null,
      orderRequestStatus: false,
    }),
    []
  );
  const [state, dispatch] = useReducer(globalReducer, initialState);

  const getAuthentication = async (source) => {
    try {
      const { data } = await axios.get(
        `${API_URL}/wp-json/${API_ENDPOINT_STRING}/v1/authentication?brand=${BRAND_ID}`,
        {
          cancelToken: source.token,
        }
      );
      dispatch({
        type: SET_TOKEN,
        payload: data.result.auth_token,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDestinations = useCallback(
    async (source) => {
      if (!state.currentDestinations) {
        try {
          const { data } = await axios.get(
            `${API_URL}/wp-json/${API_ENDPOINT_STRING}/v1/destinations?subseason=${state.currentSubSeason}&token=${state.token}`,
            {
              cancelToken: source.token,
            }
          );
          dispatch({
            type: SET_DESTINATIONS,
            payload: data.destinations,
          });
          dispatch({
            type: SET_ALL_DURATIONS,
            payload: data.durations,
          });
          dispatch({
            type: SET_COUNTRIES,
            payload: data.countries,
          });
        } catch (error) {
          console.log(error);
        }
      }
    },
    [state.currentDestinations, state.currentSubSeason, state.token]
  );

  const fetchDurations = useCallback(
    async (source) => {
      if (!state.currentDestination) {
        return;
      }
      try {
        const { data } = await axios.get(
          `${API_URL}/wp-json/${API_ENDPOINT_STRING}/v1/durations?destination_id=${state.currentDestination.code}&subseason=${state.currentSubSeason}&token=${state.token}}`,
          {
            cancelToken: source.token,
          }
        );
        dispatch({
          type: SET_DURATIONS,
          payload: data.result,
        });
      } catch (error) {
        console.log(error);
      }
    },
    [state.currentDestination, state.currentSubSeason, state.token]
  );

  const fetchTransports = useCallback(
    async (source) => {
      try {
        const { data } = await axios.get(
          `${API_URL}/wp-json/${API_ENDPOINT_STRING}/v1/transports?destination_id=${state.currentDestination.id}&token=${state.token}&duration_from=${state.currentDuration}&duration_to=${state.currentDuration}`,
          {
            cancelToken: source.token,
          }
        );
        dispatch({
          type: SET_TRANSPORTS,
          payload: data.result,
        });
      } catch (error) {
        console.log(error);
      }
    },
    [state.currentDestination, state.currentDuration, state.token]
  );

  const fetchDates = useCallback(
    async (source) => {
      try {
        const { data } = await axios.get(
          `${API_URL}/wp-json/${API_ENDPOINT_STRING}/v1/dates?destination_id=` +
            state.currentDestination.code +
            "&transport=" +
            state.currentTransport +
            "&duration_from=" +
            state.currentDuration +
            "&duration_to=" +
            state.currentDuration +
            "&subseason=" +
            state.currentSubSeason +
            "&token=" +
            state.token,
          {
            cancelToken: source.token,
          }
        );
        dispatch({
          type: SET_DATES,
          payload: data.result,
        });
      } catch (error) {
        console.log(error);
      }
    },
    [
      state.currentDestination.code,
      state.currentDuration,
      state.currentSubSeason,
      state.currentTransport,
      state.token,
    ]
  );

  const fetchCombinations = useCallback(
    async (currentAccomodationCode, currentPeriodId, single = true) => {
      try {
        const { data } =
          single === true
            ? await axios.get(
                `${API_URL}/wp-json/${API_ENDPOINT_STRING}/v1/trips/combinations?&ages=${state.currentTrip.age_string}&duration=${state.currentDuration}&date=${state.currentDate}&transport=${state.currentTransport}&subseason=${state.currentSubSeason}&token=${state.token}&accomodation_code=${currentAccomodationCode}&period_id=${currentPeriodId}&brand_id=${BRAND_ID}`
              )
            : await axios.get(
                `${API_URL}/wp-json/${API_ENDPOINT_STRING}/v1/trips/combinations/grid?&ages=${state.currentTrip.age_string}&duration=${state.currentDuration}&date=${state.currentDate}&transport=${state.currentTransport}&subseason=${state.currentSubSeason}&token=${state.token}&accomodation_code=${currentAccomodationCode}&period_id=${currentPeriodId}&brand_id=${BRAND_ID}`
              );

        dispatch({
          type: SET_CURRENT_COMBINATIONS,
          payload: data.result,
        });
      } catch (error) {
        console.log(error);
      }
    },
    [
      state.currentDate,
      state.currentDuration,
      state.currentSubSeason,
      state.currentTransport,
      state.currentTrip,
      state.token,
    ]
  );

  const fetchHotel = useCallback(
    async (accomodation_code) => {
      try {
        const { data } = await axios.get(
          `${API_URL}/wp-json/${API_ENDPOINT_STRING}/v1/hotel?accomodation_code=${accomodation_code}&subseason=${state.currentSubSeason}`
        );
        dispatch({
          type: SET_CURRENT_HOTEL,
          payload: data.result,
        });
      } catch (error) {
        console.log(error);
      }
    },
    [state.currentSubSeason]
  );

  const fetchOrder = useCallback(
    async (order_id, pincode) => {
      try {
        const { data } = await axios.get(
          `${API_URL}/wp-json/${API_ENDPOINT_STRING}/v1/orders/get?order_id=${order_id}&pincode=${pincode}&token=${state.token}`
        );
        dispatch({
          type: SET_ORDER_HTTP_STATUS,
          payload: data.httpstatus,
        });
        dispatch({
          type: SET_ORDER_REQUEST_STATUS,
          payload: data.result.order_status === "Request",
        });
        dispatch({
          type: SET_ORDER,
          payload: data.result,
        });
      } catch (error) {
        console.log(error);
      }
    },
    [state.token]
  );

  const fetchOrderCreate = useCallback(
    async (roomString) => {
      dispatch({
        type: SET_CUSTOMER_DATA,
        payload: {
          pin_code: null,
          first_name: null,
          last_name: null,
          email_address: null,
        },
      });

      try {
        const { data } = await axios.get(
          `${API_URL}/wp-json/${API_ENDPOINT_STRING}/v1/orders/create?transport=${state.currentTrip.transport_id}&token=${state.token}&period_id=${state.currentTrip.period_id}&ages=${state.currentTrip.age_string}&origin_url=${API_ENDPOINT_DOMAIN}&ip_address=35.198.722.00&room_string=${roomString}`
        );
        fetchHotel(data.result.accomodation_code);
        dispatch({
          type: SET_ORDER,
          payload: data.result,
        });

        return data.result;
      } catch (error) {
        console.log(error);
      }
    },
    [fetchHotel, state.currentTrip, state.token]
  );

  const setParticipantsData = useCallback(
    async (saveData, participant_id) => {
      const postData = {
        token: state.token,
        order_id: state.order.id,
        pincode: state.order.pin_code,
        participant: saveData,
        participant_id: participant_id,
      };

      try {
        await axios({
          url: `${API_URL}/wp-json/${API_ENDPOINT_STRING}/v1/orders/participants/attach`,
          method: "POST",
          data: postData,
        });
        fetchOrder(state.order.id, state.order.pin_code);
      } catch (error) {
        console.log(error);
      }
    },
    [fetchOrder, state.order, state.token]
  );

  const setParticipantsDataFinal = useCallback(
    async (saveData) => {
      const postData = {
        token: state.token,
        order_id: state.order.id,
        pincode: state.order.pin_code,
        participants: saveData,
      };

      try {
        await axios({
          url: `${API_URL}/wp-json/${API_ENDPOINT_STRING}/v1/orders/participants/attach/all`,
          method: "POST",
          data: postData,
        });
        fetchOrder(state.order.id, state.order.pin_code);
        dispatch({
          type: SET_PARTICIPANTS_SAVED,
          payload: true,
        });
      } catch (error) {
        console.log(error);
      }
    },
    [fetchOrder, state.order, state.token]
  );

  const setParticipantsGlobalData = (saveData, index) => {
    let globalData = state.participantsGlobalData;
    globalData[index] = saveData;
    dispatch({
      type: SET_PARTICIPANTS_GLOBAL_DATA,
      payload: globalData,
    });
  };

  const setCustomerData = useCallback(
    (saveData) => {
      const postData = {
        token: state.token,
        order_id: state.order.id,
        customer: JSON.stringify(saveData),
      };

      axios
        .post(
          `${API_URL}/wp-json/${API_ENDPOINT_STRING}/v1/orders/customers/patch`,
          postData
        )
        .then(() => {});
    },
    [state.order, state.token]
  );

  const createCustomerData = (saveData) => {
    const postData = {
      token: state.token,
      order_id: state.order.id,
      customer: JSON.stringify(saveData),
    };

    axios
      .post(
        `${API_URL}/wp-json/${API_ENDPOINT_STRING}/v1/orders/customers/create`,
        postData
      )
      .then((data) => {});
  };

  const countAdults = useCallback(
    (number) => {
      let countAdults = [];
      for (let i = 0; i < state.adults; i++) {
        countAdults.push("30");
      }
      return countAdults;
    },
    [state.adults]
  );

  const handleSubmit = useCallback(
    async (event) => {
      /*     dispatch({
      type: SET_CURRENT_TRIP,
      payload: null,
    });
 */
      if (!state.gettingTrips) {
        dispatch({
          type: SET_GETTING_TRIPS,
          payload: true,
        });

        dispatch({
          type: SET_CURRENT_COMBINATIONS,
          payload: false,
        });
        dispatch({
          type: SET_CURRENT_TRIP,
          payload: false,
        });
        dispatch({
          type: SET_CURRENT_HOTEL,
          payload: false,
        });

        const childrenFiltered = state.children
          ? state.childrenAges.filter(function (el) {
              return el != null;
            })
          : [];

        const adultsFiltered = countAdults(state.adults);

        const allAges =
          childrenFiltered && childrenFiltered.length
            ? adultsFiltered + "," + childrenFiltered.slice(0, state.children)
            : adultsFiltered;

        try {
          const { data } = await axios.get(
            `${API_URL}/wp-json/${API_ENDPOINT_STRING}/v1/trips?destination_id=${state.currentDestination.code}&ages=${allAges}&subseason=${state.currentSubSeason}&duration=${state.currentDuration}&date=${state.currentDate}&transport=${state.currentTransport}&token=${state.token}&brand_id=${BRAND_ID}`
          );

          dispatch({
            type: SET_TRIPS,
            payload: data.result,
          });

          dispatch({
            type: SET_GOT_TRIPS,
            payload: true,
          });

          dispatch({
            type: SET_GETTING_TRIPS,
            payload: false,
          });
        } catch (error) {}
      }
    },
    [
      countAdults,
      state.adults,
      state.children,
      state.childrenAges,
      state.currentDate,
      state.currentDestination.code,
      state.currentDuration,
      state.currentSubSeason,
      state.currentTransport,
      state.gettingTrips,
      state.token,
    ]
  );
  // useEffects

  useEffect(() => {
    let source = axios.CancelToken.source();
    getAuthentication(source);

    return () => {
      source.cancel();
    };
  }, []);

  useEffect(() => {
    let source = axios.CancelToken.source();
    if (state.token) {
      fetchDestinations(source);
    }
    return () => {
      source.cancel();
    };
  }, [state.token, state.currentSubSeason, fetchDestinations]);

  useEffect(() => {
    let source = axios.CancelToken.source();

    dispatch({
      type: SET_CURRENT_DATE,
      payload: state.currentDate,
    });

    if (state.currentDestination) {
      fetchDurations(source);
    }

    if (state.currentDestination && state.currentDuration) {
      fetchTransports(source);
    }

    if (
      state.currentDestination &&
      state.currentDuration &&
      state.currentTransport
    ) {
      fetchDates(source);
    }

    return () => {
      source.cancel();
    };
  }, [
    state.token,
    state.currentDestination,
    state.currentDuration,
    state.currentTransport,
    state.currentSubSeason,
    state.currentDate,
    fetchDurations,
    fetchTransports,
    fetchDates,
  ]);

  if (!state.token)
    return <Loading loading color={BRAND_ID === 5 ? "#01a6c6" : "#0e6c56"} />;

  return (
    <GlobalContext.Provider
      value={{
        token: state.token,
        destinations: state.destinations,
        allDurations: state.allDurations,
        countries: state.countries,
        currentDestination: state.currentDestination,
        durations: state.durations,
        currentDuration: state.currentDuration,
        transports: state.transports,
        currentTransport: state.currentTransport,
        adults: state.adults,
        dates: state.dates,
        currentDate: state.currentDate,
        trips: state.trips,
        gettingTrips: state.gettingTrips,
        gotTrips: state.gotTrips,
        currentTrip: state.currentTrip,
        currentSubSeason: state.currentSubSeason,
        currentHotel: state.currentHotel,
        currentCombinations: state.currentCombinations,
        children: state.children,
        childrenAges: state.childrenAges,
        order: state.order,
        orderHttpStatus: state.orderHttpStatus,
        orderRequestStatus: state.orderRequestStatus,
        participantsData: state.participantsData,
        participantsGlobalData: state.participantsGlobalData,
        participantsSaved: state.participantsSaved,
        customerData: state.customerData,
        dispatch,
        handleSubmit,
        fetchCombinations,
        fetchOrder,
        fetchOrderCreate,
        fetchHotel,
        setParticipantsData,
        setParticipantsDataFinal,
        setParticipantsGlobalData,
        setCustomerData,
        createCustomerData,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalState;

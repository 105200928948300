import { createTheme } from "@material-ui/core/styles";
import { BRAND_ID } from "../constants";

const theme = createTheme({
  typography: {
    fontFamily: ['"Lato"', "sans-serif"].join(","),
  },
  palette: {
    primary: {
      light: BRAND_ID === 5 ? "#24d9f9" : "#1f7d67",
      main: BRAND_ID === 5 ? "#01a6c6" : "#0e6c56",
      dark: BRAND_ID === 5 ? "#0084a4" : "#0d5b45",
      contrastText: "#fff",
    },
    secondary: {
      light: "#545452",
      main: "#454543",
      dark: BRAND_ID === 5 ? "#01a6c6" : "#0e6c56",
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiInputLabel: {
      root: {
        color: "#222",
        letterSpacing: "-0.3px",
        "&$disabled": {
          opacity: "0.4",
        },
      },
    },
    MuiToggleButton: {
      root: {
        color: "#222",
        backgroundColor: "#e2e2e2 !important",
        "&$selected": {
          color: "#fff",
          backgroundColor: "#0062cc!important",
        },
      },
    },
    MuiPickersToolbar: {
      toolbar: {},
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      day: {
        color: "#fff",
        backgroundColor: BRAND_ID === 5 ? "#01a6c6" : "#0e6c56",
        border: "2px solid white",
        "&:hover": {
          color: "#333",
          background: BRAND_ID === 5 ? "#01a6c6" : "#0e6c56",
        },
      },
      daySelected: {
        backgroundColor: "#666",
        border: "2px solid black",
        "&:hover": {
          color: "#fff",
        },
      },
      dayDisabled: {
        border: "none",
        color: "#aaa",
        background: "transparent",
      },
      current: {
        color: "#0f0",
      },
    },
    MuiPickersModal: {
      dialogAction: {},
    },
  },
});

export default theme;

import React from "react";
import HotelOutlinedIcon from "@material-ui/icons/HotelOutlined";
import PeopleIcon from "@mui/icons-material/People";
import DateRangeIcon from "@mui/icons-material/DateRange";
import ModeOfTravelIcon from "@mui/icons-material/ModeOfTravel";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import parse from "html-react-parser";
import format from "date-fns/format";
import da from "date-fns/locale/da";
import GetTransportName from "./GetTransportName";
import { BRAND_ID } from "../constants";

const HotelPreviewComponent = ({
  post,
  destination_name,
  room_description,
  minimum_price,
  special,
  trip,
  className,
}) => {
  return (
    <>
      <div className={`hotel_preview grid grid-cols-12 mb-2 ${className}`}>
        <div className="hotel_preview_header col-span-12 grid grid-cols-12">
          <div className="col-span-12">
            <div className="grid grid-cols-12 mb-4 mt-4 lg:mt-0 text-center lg:text-left">
              <h3 className="text-lg lg:text-xl align-bottom font-black text-gray-700 col-span-12 lg:col-span-7 mb-4 lg:mb-0 leading-none tracking-wider">
                {post?.post_title}
              </h3>
              <h6 className="text-gray-700 bg-gray-200 text-xs uppercase col-span-12 lg:col-span-5 lg:text-center font-bold rounded leading-none py-2 tracking-light align-center border border-gray-300">
                {destination_name}
              </h6>
            </div>
          </div>
        </div>
        <div className="hotel_preview_special col-span-12 grid grid-cols-12">
          <div
            style={{ borderColor: "#333" }}
            className="hotel_preview_special_info col-span-12 lg:col-span-12 text-center"
          >
            <div className="font-bold text-xs uppercase mb-0 text-gray-600 leading-tight grid grid-cols-12 gap-x-2">
              {special && (
                <>
                  <div className="text-xs uppercase text-gray-400 font-semibold p-2 bg-white rounded col-span-6 lg:col-span-3 mb-2 lg:-mb-0">
                    <PeopleIcon
                      className="align-middle mr-1"
                      style={{
                        fontSize: 17,
                        color: BRAND_ID === 5 ? "#01a6c6" : "#0e6c56",
                      }}
                    />
                    <span className="align-middle text-gray-900">
                      {trip.age_string.split(",").length}{" "}
                      {trip.age_string.split(",").length > 1
                        ? "personer"
                        : "person"}
                    </span>
                  </div>
                  <div
                    className={`text-xs uppercase text-gray-400 font-semibold p-2 bg-white rounded col-span-6 lg:col-span-3 mb-1 lg:-mb-0`}
                  >
                    <DateRangeIcon
                      className="align-middle mr-1"
                      style={{
                        fontSize: 17,
                        color: BRAND_ID === 5 ? "#01a6c6" : "#0e6c56",
                      }}
                    />
                    <span className="align-middle text-gray-900">
                      {format(new Date(trip.departure_date), "d MMM yyyy", {
                        locale: da,
                      })}
                    </span>
                  </div>
                </>
              )}
              <div
                className={`text-xs uppercase text-gray-400 font-semibold p-2 bg-white rounded col-span-6 lg:${
                  special ? "col-span-3" : "col-span-6"
                } mb-1 lg:-mb-0`}
              >
                <TimelapseIcon
                  className="align-middle mr-1"
                  style={{
                    fontSize: 17,
                    color: BRAND_ID === 5 ? "#01a6c6" : "#0e6c56",
                  }}
                />
                <span className="align-middle text-gray-900">
                  {trip.travel_length} dage
                </span>
              </div>
              <div
                className={`text-xs uppercase text-gray-400 font-semibold  p-2 bg-white rounded col-span-6 lg:${
                  special ? "col-span-3" : "col-span-6"
                } mb-1 lg:-mb-0`}
              >
                <ModeOfTravelIcon
                  className="align-middle mr-1"
                  style={{
                    fontSize: 17,
                    color: BRAND_ID === 5 ? "#01a6c6" : "#0e6c56",
                  }}
                />
                <span className="align-middle text-gray-900">
                  <GetTransportName code={trip.transport_code} />
                </span>
              </div>
            </div>
          </div>
        </div>
        <h4
          style={{ color: BRAND_ID === 5 ? "#01a6c6" : "#0e6c56" }}
          className="col-span-12 lg:col-span-12 mt-2 uppercase text-xs lg:text-sm text-center lg:text-left leading-none inline font-bold"
        >
          <HotelOutlinedIcon
            variant="outlined"
            style={{ fontSize: 22 }}
            className="align-middle mr-2 text-gray-400"
          />
          <span className="mt-1 align-middle leading-none">
            {room_description}
          </span>
        </h4>
      </div>
      {post?.snippet && (
        <div className="hotel_beskrivelse--reduceret text-sm mb-4">
          {parse(post?.snippet)}...
        </div>
      )}
    </>
  );
};
export default HotelPreviewComponent;

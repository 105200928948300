export const SET_LOADING = "SET_LOADING";
export const SET_TOKEN = "SET_TOKEN";
export const SET_DESTINATIONS = "SET_DESTINATIONS";
export const SET_ALL_DURATIONS = "SET_ALL_DURATIONS";
export const SET_COUNTRIES = "SET_COUNTRIES";
export const SET_CURRENT_DESTINATION = "SET_CURRENT_DESTINATION";
export const SET_DURATIONS = "SET_DURATIONS";
export const SET_CURRENT_DURATION = "SET_CURRENT_DURATION";
export const SET_TRANSPORTS = "SET_TRANSPORTS";
export const SET_CURRENT_TRANSPORT = "SET_CURRENT_TRANSPORT";
export const SET_ADULTS = "SET_ADULTS";
export const SET_DATES = "SET_DATES";
export const SET_CURRENT_DATE = "SET_CURRENT_DATE";
export const SET_TRIPS = "SET_TRIPS";
export const SET_CURRENT_TRIP = "SET_CURRENT_TRIP";
export const SET_GETTING_TRIPS = "SET_GETTING_TRIPS";
export const SET_GOT_TRIPS = "SET_GOT_TRIPS";
export const SET_CURRENT_SUB_SEASON = "SET_CURRENT_SUB_SEASON";
export const SET_CURRENT_HOTEL = "SET_CURRENT_HOTEL";
export const SET_CURRENT_COMBINATIONS = "SET_CURRENT_COMBINATIONS";
export const SET_CHILDREN = "SET_CHILDREN";
export const SET_CHILDREN_AGES = "SET_CHILDREN_AGES";
export const SET_ORDER = "SET_ORDER";
export const SET_ORDER_HTTP_STATUS = "SET_ORDER_HTTP_STATUS";
export const SET_ORDER_REQUEST_STATUS = "SET_ORDER_REQUEST_STATUS";
export const SET_PARTICIPANTS_DATA = "SET_PARTICIPANTS_DATA";
export const SET_PARTICIPANTS_GLOBAL_DATA = "SET_PARTICIPANTS_GLOBAL_DATA";
export const SET_PARTICIPANTS_SAVED = "SET_PARTICIPANTS_SAVED";
export const SET_CUSTOMER_DATA = "SET_CUSTOMER_DATA";
export const CREATE_CUSTOMER_DATA = "SET_CUSTOMER_DATA";

import React, { useContext, useEffect, useState } from "react";
import globalContext from "../context/global/globalContext";
import { useDateConverter } from "./generic-functions/useDateConverter";

const OrderTotal = ({ order, tailwindCSS }) => {
  const { currentHotel } = useContext(globalContext);
  const convertDate = useDateConverter();

  const formatter = new Intl.NumberFormat("da-DK", {
    style: "currency",
    currency: "DKK",
  });

  const [hotelTitle, setHotelTitle] = useState("");

  useEffect(() => {
    if (currentHotel && currentHotel.post_title) {
      setHotelTitle(
        currentHotel.post_title.toLowerCase().includes("- sommer")
          ? currentHotel?.post_title?.split("-").shift()
          : currentHotel?.post_title
      );
    }
  }, [currentHotel]);
  return (
    <div
      className={`rounded bg-gray-100 border border-gray-200 ${tailwindCSS}`}
    >
      <>
        <div className="p-4">
          <h4 className="leading-tight sm:mb-0 font-bold text-lg text-gray-600 text-center tracking-widest">
            Din rejse
          </h4>
        </div>
        <div
          className={`mx-4 bg-cover bg-center h-36`}
          style={{
            backgroundImage: `url(${currentHotel?.images?.full[0]})`,
          }}
        />
        <div className={`p-4`}>
          <div className="pb-4">
            <h6 className="font-bold text-xl">{hotelTitle}</h6>

            <p className="text-xs m-0">
              {order?.participants &&
                order.participants[0].room_description &&
                order.participants[0].room_description}
            </p>
          </div>
          <div className="order-total-items border-b border-t border-gray-400 py-4">
            <div className="flex justify-between my-1 text-sm">
              <p className="m-0 font-semibold">Uge</p>
              <p className="m-0">{order?.week_no}</p>
            </div>
            <div className="flex justify-between my-1 text-sm">
              <p className="m-0 font-semibold">Tjek ind</p>
              <p className="m-0">{convertDate(order?.departure_date)}</p>
            </div>
            <div className="flex justify-between my-1 text-sm">
              <p className="m-0 font-semibold">Tjek ud</p>
              <p className="m-0">{convertDate(order?.return_date)}</p>
            </div>
            <div className="flex justify-between my-1 text-sm">
              <p className="m-0 font-semibold">Rejselængde</p>
              <p className="m-0">{order?.travel_length} dage</p>
            </div>
            <div className="flex justify-between my-1 text-sm">
              <p className="m-0 font-semibold">Transport</p>
              <p className="m-0">{order?.transport_name}</p>
            </div>
          </div>

          <h6 className="font-bold text-base">
            Totalpris: {formatter.format(order?.total_amount)}
          </h6>
        </div>
      </>
    </div>
  );
};

export default OrderTotal;

import React, { useContext } from "react";
import OrderForm from "../components/OrderForm";
import OrderTotal from "../components/OrderTotal";
import globalContext from "../context/global/globalContext";

const Order = () => {
  const { order } = useContext(globalContext);

  return (
    <>
      <div className="grid grid-cols-12" style={{ minHeight: "400px" }}>
        {order && (
          <>
            <OrderForm tailwindCSS="col-span-12 mb-4 lg:mb-0 lg:col-span-8 pr-0 lg:pr-6" />
            <OrderTotal
              order={order && order}
              tailwindCSS="col-span-12 lg:col-span-4"
            />
          </>
        )}
      </div>
    </>
  );
};

export default Order;

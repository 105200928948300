import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import React, { useContext } from "react";
import globalContext from "../../context/global/globalContext";
import {
  SET_CURRENT_COMBINATIONS,
  SET_CURRENT_TRIP,
  SET_TRIPS,
  SET_CURRENT_DESTINATION,
} from "../../context/types";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import { BRAND_ID } from "../../constants";

const DestinationsSelect = () => {
  const { destinations, currentDestination, dispatch } =
    useContext(globalContext);

  return (
    <FormControl variant="outlined" className="col-span-6 lg:col-span-1">
      <InputLabel shrink={true} id="destinations">
        {!currentDestination && (
          <LocationOnOutlinedIcon
            style={{
              //color: BRAND_ID === 5 ? "#78a7c8" : "#0e6c56",
              color: BRAND_ID === 5 ? "#01a6c6" : "#0e6c56",
              padding: "0",
              margin: "0 2px 0 0",
              fontSize: "16px",
              verticalAlign: "top",
              fontWeight: "700",
            }}
          />
        )}
        Rejsemål
      </InputLabel>
      <Select
        id="destinations"
        value={currentDestination ? currentDestination : "placeholder"}
        label="destinations"
        className="mb-5 lg:mb-0 mr-0 lg:mr-2"
        onChange={(e) => {
          dispatch({
            type: SET_CURRENT_DESTINATION,
            payload: e.target.value,
          });
          dispatch({
            type: SET_TRIPS,
            payload: null,
          });
          dispatch({
            type: SET_CURRENT_TRIP,
            payload: null,
          });
          dispatch({
            type: SET_CURRENT_COMBINATIONS,
            payload: null,
          });
        }}
      >
        <MenuItem disabled value="placeholder">
          Vælg
        </MenuItem>
        {destinations?.map((item) =>
          item.destinations.map((destination_item, destination_key) => {
            return (
              <MenuItem
                disabled={destination_item.type === "country" ? true : false}
                key={destination_key}
                value={destination_item}
                className={
                  destination_item.type === "country" ? "opacity-100" : ""
                }
                style={
                  destination_item.type === "country"
                    ? {
                        opacity: "0.5",
                      }
                    : {}
                }
              >
                <span
                  style={
                    destination_item.type === "country"
                      ? {
                          display: "inline-block",
                          width: "100%",
                        }
                      : {}
                  }
                  className={
                    destination_item.type === "country"
                      ? "border border-t-0 border-l-0 border-r-0 border-solid border-black font-semibold text-black v-full"
                      : "font-regular"
                  }
                >
                  {destination_item.type === "country"
                    ? destination_item.translated
                      ? destination_item.translated
                      : destination_item.name
                    : destination_item.name}
                </span>
              </MenuItem>
            );
          })
        )}
      </Select>
    </FormControl>
  );
};

export default DestinationsSelect;

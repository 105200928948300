import React, { useCallback, useContext, useEffect, useState } from "react";
import CarouselComponent from "./CarouselComponent";
import globalContext from "../context/global/globalContext";
import parse from "html-react-parser";
import {
  SET_ORDER_REQUEST_STATUS,
  SET_PARTICIPANTS_DATA,
  SET_CURRENT_HOTEL,
} from "../context/types";
import { useHistory } from "react-router-dom";
import { Button, Tab, Tabs, Box, Typography } from "@material-ui/core";
import HotelMetaComponent from "./HotelMetaComponent";
import HotelPreviewComponent from "./HotelPreviewComponent";
import HotelPriceComponent from "./HotelPriceComponent";
import HotelTravelDetailsComponent from "./HotelTravelDetailsComponent";
import GetTransportName from "./GetTransportName";
import { BRAND_ID } from "../constants";

const DetailsUcpa = (props) => {
  const history = useHistory();
  const [tabIndex, setTabIndex] = useState(0);
  const {
    currentTrip,
    fetchCombinations,
    currentCombinations,
    fetchOrderCreate,
  } = useContext(globalContext);

  const { dispatch } = useContext(globalContext);

  const {
    destination_name,
    room_description,
    minimum_price,
    post,
    accomodation_code,
    period_id,
  } = currentTrip;

  var formatter = new Intl.NumberFormat("da-DK", {
    style: "currency",
    currency: "DKK",
  });

  useEffect(() => {
    if (!currentCombinations) {
      fetchCombinations(accomodation_code, period_id);
    }
  }, [
    currentTrip,
    currentCombinations,
    fetchCombinations,
    accomodation_code,
    period_id,
  ]);

  const handleTabIndex = useCallback((event, newValue) => {
    setTabIndex(newValue);
  }, []);

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="relative mt-10 mb-10 p-6 border-4 border-gray-500 rounded bg-gray-100">
        <div className="grid mb-6 grid-cols-12">
          <div className="col-span-12 lg:col-span-7 mr-0 lg:mr-5 mb-4 lg:mb-0">
            {post && destination_name && room_description && minimum_price && (
              <HotelPreviewComponent
                post={post}
                trip={currentTrip}
                destination_name={destination_name}
                room_description={room_description}
                minimum_price={minimum_price}
                special={props.special}
              />
            )}
            <div className="travel-infobox rounded overflow-hidden mb-2 lg:mb-5 border border-gray-300">
              <h4 className="text-gray-800 text-center text-xs uppercase mb-0 font-semibold bg-gray-200 px-5  py-2">
                Detaljer
              </h4>

              {post?.meta && (
                <HotelMetaComponent trip={currentTrip} post={post} />
              )}
            </div>

            <div className="travel-infobox rounded overflow-hidden mb-2 lg:mb-5 border border-gray-300">
              <h4 className="text-gray-800 text-center text-xs uppercase mb-0 font-semibold bg-gray-200 px-5  py-2">
                Rejseinformation
              </h4>

              {post?.meta && <HotelTravelDetailsComponent trip={currentTrip} />}
            </div>
          </div>
          <div className={"col-span-12 lg:col-span-5"}>
            {post && destination_name && room_description && minimum_price && (
              <HotelPriceComponent
                post={post}
                trip={currentTrip}
                destination_name={destination_name}
                room_description={room_description}
                minimum_price={minimum_price}
              />
            )}
            {post?.meta?.gallery_settings && (
              <CarouselComponent
                className="rounded overflow-hidden"
                images={post.meta.gallery_settings}
                height="h-80 lg:h-96"
              />
            )}
          </div>
        </div>

        <div className="trip-options">
          <Tabs
            variant="fullWidth"
            value={tabIndex}
            onChange={handleTabIndex}
            aria-label="Muligheder"
          >
            <Tab
              style={{
                background: BRAND_ID === 5 ? "#01a6c6" : "#0e6c56",
                color: "#fff",
                marginRight: "1.333%",
                borderRadius: "3px",
              }}
              label="Priser"
              {...a11yProps(0)}
            />
            <Tab
              style={{
                background: BRAND_ID === 5 ? "#01a6c6" : "#0e6c56",
                color: "#fff",
                marginRight: "1.333%",
                borderRadius: "3px",
              }}
              label="Hotel"
              {...a11yProps(1)}
            />
            <Tab
              style={{
                background: BRAND_ID === 5 ? "#01a6c6" : "#0e6c56",
                color: "#fff",
                marginRight: "1.333%",
                borderRadius: "3px",
              }}
              label="Fakta"
              {...a11yProps(2)}
            />
            <Tab
              style={{
                background: BRAND_ID === 5 ? "#01a6c6" : "#0e6c56",
                color: "#fff",
                marginRight: "0",
                borderRadius: "3px",
              }}
              label="Kort"
              {...a11yProps(3)}
            />
          </Tabs>

          <TabPanel className="pt-4 animate-focus" value={tabIndex} index={0}>
            {currentCombinations && (
              <>
                <div className="hidden lg:grid bg-gray-400 text-gray-50 px-4 py-2 grid-cols-12">
                  <div className="col-span-12 lg:col-span-5 font-semibold">
                    Værelser
                  </div>
                  <div className="col-span-12 lg:col-span-2 font-semibold">
                    Rejseinfo
                  </div>
                  <div className="col-span-12 lg:col-span-2 font-semibold">
                    Dato
                  </div>
                  <div className="col-span-12 lg:col-span-2 font-semibold">
                    Pris
                  </div>
                  <div className="col-span-12 lg:col-span-1"></div>
                </div>
                {currentCombinations?.map((combination, index) => (
                  <div
                    key={index}
                    className={`${
                      index % 2 === 0 ? "bg-gray-200" : "bg-gray-50"
                    } grid grid-cols-12 p-4`}
                  >
                    <div className="col-span-12 lg:col-span-5 pt-2 pr-4 font-bold lg:font-normal text-xs text-center lg:text-left">
                      {combination.rooms.rooms_description}
                    </div>
                    <div className="block lg:hidden col-span-12 lg:col-span-2 pt-2 text-xs text-center font-bold">
                      Transport
                    </div>
                    <div className="col-span-12 lg:col-span-2 pt-0 lg:pt-2 font-normal text-xs text-center lg:text-left">
                      <GetTransportName code={combination.transport_code} />{" "}
                      {combination.current_week.display_days} dage
                    </div>
                    <div className="block lg:hidden col-span-12 lg:col-span-2 pt-2 text-xs text-center font-bold">
                      Afrejsedato
                    </div>
                    <div className="col-span-12 lg:col-span-2 pt-0 lg:pt-2 font-normal text-xs text-center lg:text-left">
                      {combination.current_week.departure_date}
                    </div>
                    <div className="block lg:hidden col-span-12 lg:col-span-2 pt-2 text-xs text-center font-bold">
                      Pris
                    </div>
                    <div className="col-span-12 lg:col-span-2 pt-0 lg:pt-2 font-normal text-xs text-center lg:text-left">
                      {formatter.format(combination.current_week.price)}
                    </div>
                    <div className="col-span-12 lg:col-span-1 mt-4 lg:mt-0 text-sm text-center lg:text-right">
                      {!combination.rooms.room_on_request ? (
                        <Button
                          onClick={() => {
                            dispatch({
                              type: SET_PARTICIPANTS_DATA,
                              payload: [],
                            });
                            dispatch({
                              type: SET_ORDER_REQUEST_STATUS,
                              payload: true,
                            });
                            dispatch({
                              type: SET_CURRENT_HOTEL,
                              payload: false,
                            });

                            fetchOrderCreate(combination.rooms.room_string);
                            history.push("order" + history.location.search);
                          }}
                          color="primary"
                          variant="contained"
                        >
                          Forespørg
                        </Button>
                      ) : (
                        <Button
                          onClick={() => {
                            dispatch({
                              type: SET_PARTICIPANTS_DATA,
                              payload: [],
                            });
                            dispatch({
                              type: SET_ORDER_REQUEST_STATUS,
                              payload: false,
                            });
                            dispatch({
                              type: SET_CURRENT_HOTEL,
                              payload: null,
                            });

                            fetchOrderCreate(combination.rooms.room_string);
                            history.push("order" + history.location.search);
                          }}
                          color="primary"
                          variant="contained"
                        >
                          Bestil
                        </Button>
                      )}
                    </div>
                  </div>
                ))}
              </>
            )}
          </TabPanel>

          <TabPanel className="pt-4" value={tabIndex} index={1}>
            {post?.description && <div>{parse(post?.description)}</div>}
          </TabPanel>

          <TabPanel className="pt-4" value={tabIndex} index={2}>
            {post?.meta?.hotel_fakta && (
              <div
                className={`${
                  tabIndex === 2 ? "block" : "hidden"
                } hotel_beliggenhed`}
              >
                <HotelMetaComponent trip={currentTrip} post={post} />
              </div>
            )}
          </TabPanel>
          <TabPanel className="pt-4" value={tabIndex} index={3}>
            {post?.location && <div>{parse(post?.location)}</div>}
          </TabPanel>
        </div>
      </div>{" "}
      {!props.singular && (
        <div className="text-center">
          <h6
            style={{ borderColor: BRAND_ID === 5 ? "#01a6c6" : "#0e6c56" }}
            className="px-8 py-2 text-base inline-block text-center uppercase font-bold border-2 text-gray-600 my-8 rounded"
          >
            Alternative rejsemuligheder
          </h6>
        </div>
      )}
    </>
  );
};

export default DetailsUcpa;

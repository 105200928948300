// import { Link } from "react-router-dom";
import React, { useContext, useEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import CarouselComponent from "./CarouselComponent";
import HotelMetaComponent from "./HotelMetaComponent";
import HotelPreviewComponent from "./HotelPreviewComponent";
import HotelPriceComponent from "./HotelPriceComponent";
import HotelTravelDetailsComponent from "./HotelTravelDetailsComponent";
import {
  SET_CURRENT_TRIP,
  SET_CURRENT_COMBINATIONS,
  SET_CURRENT_HOTEL,
} from "../context/types";
import globalContext from "../context/global/globalContext";
import { Button } from "@material-ui/core";
import { BRAND_ID } from "../constants";

const Trip = ({ trip }) => {
  const {
    dispatch,
    currentDestination,
    currentDuration,
    currentTransport,
    currentSubSeason,
    currentDate,
  } = useContext(globalContext);

  const { destination_name, room_description, minimum_price, post } = trip;

  const overwrite_destination =
    BRAND_ID === 5
      ? window.hey_destination_id
        ? window.hey_destination_id
        : false
      : false;

  const history = useHistory();

  const heySearch = useLocation().search;
  const query = useMemo(() => new URLSearchParams(heySearch), [heySearch]);

  let query_accomodation = overwrite_destination
    ? trip.accomodation_code
    : query.get("accomodation");
  let query_period_id = overwrite_destination
    ? trip.period_id
    : query.get("period_id");

  useEffect(() => {
    let transport_string = "transport_" + trip.transport_code;
    if (
      query_accomodation &&
      query_period_id &&
      query_accomodation === trip.accomodation_code &&
      transport_string === currentTransport &&
      trip.period_id === parseInt(query_period_id)
    ) {
      dispatch({
        type: SET_CURRENT_TRIP,
        payload: trip,
      });
      dispatch({
        type: SET_CURRENT_COMBINATIONS,
        payload: false,
      });
      dispatch({
        type: SET_CURRENT_HOTEL,
        payload: false,
      });
    }
  }, [trip, query_accomodation, currentTransport, query_period_id, dispatch]);

  if (!post) {
    return null;
  }

  return (
    <div className="relative mt-8 mb-12 px-4 lg:px-6 py-4 lg:py-6 border-2 border-dashed border-gray-300 rounded bg-gray-100">
      <div className="grid md:grid-cols-12 grid-cols-1">
        <div className="col-span-12 lg:col-span-5 lg:hidden">
          <>
            {post?.meta?.gallery_settings && (
              <CarouselComponent
                className="rounded overflow-hidden mb-0"
                height="h-56 lg:h-72"
                images={post.meta.gallery_settings}
              />
            )}
          </>
        </div>
        <div className="col-span-12 lg:col-span-7 lg:relative pr-0 lg:pr-6">
          {post && destination_name && room_description && minimum_price && (
            <HotelPreviewComponent
              post={post}
              trip={trip}
              destination_name={destination_name}
              room_description={room_description}
              minimum_price={minimum_price}
            />
          )}
          <div className="travel-infobox rounded overflow-hidden mb-2 lg:mb-5 border border-gray-300">
            <h4 className="text-gray-800 text-center text-xs uppercase mb-0 font-semibold bg-gray-200 px-5  py-2">
              Detaljer
            </h4>
            {post?.meta && <HotelMetaComponent trip={trip} post={post} />}
          </div>

          <div className="travel-infobox rounded overflow-hidden mb-2 lg:mb-5 border border-gray-300">
            <h4 className="text-gray-800 text-center text-xs uppercase mb-0 font-semibold bg-gray-200 px-5  py-2">
              Rejseinformation
            </h4>

            {post?.meta && <HotelTravelDetailsComponent trip={trip} />}
          </div>
        </div>
        <div className="col-span-12 lg:col-span-5 mb-2 lg:mb-0">
          {post?.meta?.gallery_settings && (
            <CarouselComponent
              className="rounded overflow-hidden mb-4 hidden lg:block"
              height="h-56 lg:h-72"
              images={post.meta.gallery_settings}
            />
          )}
          {post && destination_name && room_description && minimum_price && (
            <HotelPriceComponent
              className="mt-4 lg:mt-0"
              post={post}
              trip={trip}
              destination_name={destination_name}
              room_description={room_description}
              minimum_price={minimum_price}
              special={true}
            />
          )}
          <div className="col-span-12 mt-4">
            <Button
              className="w-full"
              color="primary"
              variant="contained"
              size="large"
              onClick={() => {
                dispatch({
                  type: SET_CURRENT_TRIP,
                  payload: trip,
                });
                dispatch({
                  type: SET_CURRENT_COMBINATIONS,
                  payload: false,
                });
                dispatch({
                  type: SET_CURRENT_HOTEL,
                  payload: false,
                });
                history.push(
                  `?destination_id=${currentDestination.code}&ages=${trip.age_string}&duration=${currentDuration}&date=${currentDate}&transport=${currentTransport}&accomodation=${trip.accomodation_code}&period_id=${trip.period_id}&subseason=${currentSubSeason}`
                );
              }}
            >
              Se Hotel & Priser
            </Button>
          </div>
        </div>
      </div>
      {/* </Link> */}
    </div>
  );
};

export default Trip;

// <Link to="/hotel/details" className="p-4 flex flex-col space-y-6 "></Link>

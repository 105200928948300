import React, {
  useEffect,
  useMemo,
  useContext,
  useRef,
  useCallback,
} from "react";
import { useLocation, useHistory } from "react-router-dom";

import {
  SET_TRIPS,
  SET_CURRENT_TRIP,
  SET_ORDER_REQUEST_STATUS,
  SET_PARTICIPANTS_DATA,
  SET_CURRENT_HOTEL,
} from "../context/types";
import globalContext from "../context/global/globalContext";

const CreateOrder = () => {
  const [, updateState] = React.useState();
  const isMounted = useRef(false);
  const hasDispatched = useRef(false);
  const isCreatingOrder = useRef(false);
  const { search } = useLocation();
  const history = useHistory();
  const { dispatch, fetchOrderCreate } = useContext(globalContext);
  const query = useMemo(() => new URLSearchParams(search), [search]);

  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      const trip = {
        transport_id: query.get("transport_id"),
        period_id: query.get("period_id"),
        age_string: query.get("ages"),
      };

      dispatch({
        type: SET_TRIPS,
        payload: [trip],
      });
      dispatch({
        type: SET_CURRENT_TRIP,
        payload: trip,
      });
      dispatch({
        type: SET_PARTICIPANTS_DATA,
        payload: [],
      });
      dispatch({
        type: SET_ORDER_REQUEST_STATUS,
        payload: false,
      });
      dispatch({
        type: SET_CURRENT_HOTEL,
        payload: null,
      });

      setTimeout(() => {
        hasDispatched.current = true;
        forceUpdate();
      }, 1000);
    }
  });

  useEffect(() => {
    if (hasDispatched.current && !isCreatingOrder.current) {
      isCreatingOrder.current = true;
      const newQuery = new URLSearchParams(query);

      const roomString = newQuery.get("roomString");
      newQuery.delete("roomString");

      fetchOrderCreate(roomString).then((result) => {
        newQuery.delete("transport_id");
        const url = `/order?${newQuery.toString()}`;

        history.replace(url);
      });
    }
  });

  return null;
};

export default CreateOrder;

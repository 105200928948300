import React, { useContext, useEffect } from "react";
import OrderConfirmationForm from "../components/OrderConfirmationForm";
import OrderTotal from "../components/OrderTotal";
import globalContext from "../context/global/globalContext";

const OrderConfirmation = () => {
  const { order, fetchHotel, currentHotel } = useContext(globalContext);

  useEffect(() => {
    if (order && order.accomodation_code) {
      fetchHotel(order.accomodation_code);
    }
  }, [fetchHotel, order]);

  return (
    <div className="grid grid-cols-12">
      <OrderConfirmationForm tailwindCSS="col-span-12 lg:col-span-8 pr-6" />
      {currentHotel && (
        <OrderTotal
          order={order && order}
          tailwindCSS="col-span-12 lg:col-span-4"
        />
      )}
    </div>
  );
};

export default OrderConfirmation;

export const BRAND_ID = 2;
export const PAX_DEFAULT = BRAND_ID === 7 || BRAND_ID === 5 ? 1 : 2;
export const PAX_DEFAULT_STRING =
  BRAND_ID === 7 || BRAND_ID === 5 ? "30" : "30,30";
export const API_URL =
  BRAND_ID === 5
    ? "https://ucpa.dk"
    : BRAND_ID === 18
    ? "https://vhservice.dk"
    : "https://thinggaard.dk";
export const API_ENDPOINT_STRING =
  BRAND_ID === 5 ? "ucpa" : BRAND_ID === 18 ? "thinggaard" : "thinggaard";
export const API_ENDPOINT_DOMAIN =
  BRAND_ID === 5
    ? "www.ucpa.dk"
    : BRAND_ID === 18
    ? "vhservice.dk"
    : "thinggaard.dk";

//@ts-check

import { SortTypes } from "./Sorts";

/**
 * @param {number} sortType The sort direction
 * @returns {(a: any, b: any) => number} The resulting sort method
 */
export const tripSorting = (sortType) => {
  switch (sortType) {
    case SortTypes.ASC:
      return (a, b) => parseInt(a.minimum_price) - parseInt(b.minimum_price);
    case SortTypes.DESC:
      return (a, b) => parseInt(b.minimum_price) - parseInt(a.minimum_price);
    case SortTypes.POP:
      return (a, b) => (a.post?.ID ?? 1) - (b.post?.ID ?? 0);
    default:
      return () => 0;
  }
};

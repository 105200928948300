import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { useRef, useState } from "react";
import { SortText, SortTypes } from "./Sorts";

/**
 * @param {{sort: number, setSort: (s: number) => void}} props The sort direction
 * @returns {any} The resulting sort method
 */
const SortTripsMenu = (props) => {
  const divRef = useRef();
  const [anchor, setAnchor] = useState(null);
  const [selected, setSelected] = useState(props.sort);

  const openMenu = () => {
    anchor ? setAnchor(null) : setAnchor(divRef.current);
  };

  const applySort = (sort) => {
    props.setSort(sort);
    setSelected(sort);
    openMenu();
  };
  return (
    <FormControl
      variant="outlined"
      ref={divRef}
      style={{
        marginTop: "2em",
        background: "#F3F4F6",
        width: "10em",
      }}
    >
      <InputLabel shrink={true} style={{ fontWeight: 400 }}>
        Sortér
      </InputLabel>
      <Select
        value={selected}
        label="Sortér"
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
        style={{
          height: "3em",
        }}
      >
        {Object.keys(SortTypes).map((k, v) => (
          <MenuItem
            key={k}
            onClick={() => applySort(v)}
            value={v}
            style={{
              paddingInlineEnd: "2em",
            }}
          >
            {SortText[k]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SortTripsMenu;

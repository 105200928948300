import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import format from "date-fns/format";
import da from "date-fns/locale/da";
import React, { useContext, useMemo, useState } from "react";
import globalContext from "../../context/global/globalContext";
import { SET_CURRENT_DATE } from "../../context/types";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import { BRAND_ID } from "../../constants";

const DatesSelect = () => {
  const [pickerStatus, setPickerStatus] = useState(false);

  const {
    destinations,
    currentDuration,
    currentTransport,
    dates,
    currentDate,
    dispatch,
  } = useContext(globalContext);

  const daylist = dates?.map((date) => date.date);
  const renderDayInPicker = (
    date,
    selectedDate,
    dayInCurrentMonth,
    dayComponent
  ) => {
    for (var i = 0; i < currentDuration; i++) {
      let rangeDate = new Date(date);
      rangeDate.setDate(rangeDate.getDate() - i);
      if (daylist.includes(format(rangeDate, "yyyy-MM-dd"))) {
        return <div className="day-in-range">{dayComponent}</div>;
      }
    }
    return dayComponent;
  };
  const disableDays = (date) => {
    if (!daylist.includes(format(date, "yyyy-MM-dd"))) {
      return true;
    }
  };

  const nullCheck = useMemo(
    () => currentTransport && currentDuration,
    [currentTransport, currentDuration]
  );

  return (
    <div className="col-span-6 lg:col-span-1 mb-5 lg:mb-0 mr-0 lg:mr-2">
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={da}>
        <KeyboardDatePicker
          keyboardIcon={
            <DateRangeOutlinedIcon
              style={{
                //color: BRAND_ID === 5 ? "#78a7c8" : "#0e6c56",
                color: BRAND_ID === 5 ? "#01a6c6" : "#0e6c56",
                padding: "0",
                margin: "0 2px 0 0",
                fontSize: "16px",
                verticalAlign: "top",
                fontWeight: "700",
                opacity: destinations && nullCheck ? "1.0" : "0.4",
              }}
            />
          }
          disabled={!nullCheck}
          onClick={() => setPickerStatus(nullCheck)}
          onClose={() => setPickerStatus(false)}
          open={pickerStatus}
          disableToolbar
          shouldDisableDate={disableDays}
          clearable={false}
          autoOk={true}
          animateYearScrolling={true}
          value={currentDate}
          label="Dato"
          InputLabelProps={{ shrink: true }}
          onChange={(date) => {
            date.setHours(12, 0, 0);
            dispatch({
              type: SET_CURRENT_DATE,
              payload: date.getTime(),
            });
          }}
          minDate={new Date()}
          disablePast
          format="dd/MM/yyyy"
          renderDay={renderDayInPicker}
          inputVariant="outlined"
          allowKeyboardControl={false}
          inputProps={{ style: { letterSpacing: "-0.2px" } }}
          variant="dialog"
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default DatesSelect;

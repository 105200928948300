import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import React, { useContext } from "react";
import globalContext from "../../context/global/globalContext";
import { SET_CURRENT_TRANSPORT } from "../../context/types";
import CommuteOutlinedIcon from "@material-ui/icons/CommuteOutlined";
import { BRAND_ID } from "../../constants";

const TransportsSelect = () => {
  const { transports, currentTransport, dispatch } = useContext(globalContext);

  const GetTransportTitle = (props) => {
    let returnTitle = props.title.toLowerCase();
    switch (returnTitle) {
      case "car":
        returnTitle = "Egen Transport";
        break;
      case "train":
        returnTitle = "Tog";
        break;
      case "flight":
        returnTitle = "Fly";
        break;
      default:
        returnTitle = "Bus";
        break;
    }
    return returnTitle;
  };

  return (
    <FormControl
      variant="outlined"
      className="col-span-6 lg:col-span-1"
      disabled={transports ? false : true}
    >
      <InputLabel shrink={true} id="label-transport">
        {!currentTransport && (
          <CommuteOutlinedIcon
            style={{
              color: BRAND_ID === 5 ? "#01a6c6" : "#0e6c56",
              padding: "0",
              margin: "0 2px 0 0",
              fontSize: "16px",
              verticalAlign: "top",
              fontWeight: "700",
            }}
          />
        )}
        Transport
      </InputLabel>
      <Select
        labelId="label-transport"
        label="transport"
        id="select-transport"
        className="mb-5 lg:mb-0 mr-0 lg:mr-2"
        value={currentTransport ? currentTransport : "placeholder"}
        onChange={(e) => {
          dispatch({
            type: SET_CURRENT_TRANSPORT,
            payload: e.target.value,
          });
        }}
      >
        <MenuItem disabled value="placeholder">
          {transports ? "Vælg" : ""}
        </MenuItem>
        {transports?.map((item) => (
          <MenuItem
            key={item.transport_category_id}
            value={"transport_" + item.transport_category_id}
          >
            <GetTransportTitle title={item.transport_category_name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default TransportsSelect;

const GetTransportName = ({ code }) => {
  const convertTransportName = (mycode) => {
    switch (mycode) {
      case 0:
        return "Kør selv";
      case 1:
        return "Bus";
      case 2:
        return "Fly";
      default:
        return "Kør selv";
    }
  };

  return <>{convertTransportName(code)}</>;
};

export default GetTransportName;

import React from "react";
import { BRAND_ID } from "../constants";

/**
 * @param {{post: any, destination_name: string, room_description: string}} props The sort direction
 * @returns {any} The resulting sort method
 */
const HotelPriceComponent = ({
  post,
  destination_name,
  room_description,
  minimum_price,
  special,
  trip,
  className,
}) => {
  var formatter = new Intl.NumberFormat("da-DK", {
    style: "currency",
    currency: "DKK",
  });

  return (
    <>
      <div
        className={`hhotel_preview_header col-span-12 grid grid-cols-12 mb-4 ${className}`}
      >
        <div
          className="p-4 col-span-12 lg:col-span-12 border rounded  bg-white"
          style={{ borderColor: BRAND_ID === 5 ? "#01a6c6" : "#0e6c56" }}
        >
          <h6 className="m-0 grid grid-cols-12 text-base leading-none">
            <span className="text-gray-500 col-span-7 text-left font-normal">
              {trip?.last_minute || trip?.offer
                ? "Total pris"
                : "Total pris fra"}
            </span>
            <span className="text-gray-500 font-semibold text-right col-span-5">{`${formatter.format(
              minimum_price
            )}`}</span>
          </h6>
          <h6 className="m-0 mt-4 lg:mt-0 grid grid-cols-12 text-base leading-none">
            <span className="text-gray-700 col-span-7 text-left font-semibold">
              {trip?.last_minute || trip?.offer
                ? "Pris pr. person"
                : "Pris pr. person fra"}
            </span>{" "}
            <span className="text-gray-900 font-semibold col-span-5 text-right">{`${formatter.format(
              minimum_price / trip.age_string.split(",").length
            )}`}</span>
          </h6>
          {trip?.discount_price !== "0.0" && (
            <div className="text-center pt-2">
              <span
                style={{ borderColor: BRAND_ID === 5 ? "#01a6c6" : "#0e6c56" }}
                className="py-1 px-2 inline-block text-center rounded w-full leading-4 align-middle bg-discount font-bold"
              >
                <span className="text-white">Samlet rabat </span>
                <span
                  style={{ color: BRAND_ID === 5 ? "#01a6c6" : "white" }}
                  className="text-base"
                >
                  {formatter.format(0 - trip.discount_price)}
                </span>
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default HotelPriceComponent;

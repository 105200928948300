import React from "react";
import { useContext } from "react";

import InfoOutlinedIcon from "@material-ui/icons/LocationCityOutlined";
import HotelOutlinedIcon from "@material-ui/icons/InfoOutlined";

import globalContext from "../context/global/globalContext";

const HotelMetaComponent = ({ trip, post }) => {
  const {
    arrival_airport_code_for_departure,
    departure_airport_code_for_departure,
    destination_code,
  } = trip;

  const { currentSubSeason } = useContext(globalContext);

  return currentSubSeason && currentSubSeason === "summer" ? (
    <div className="grid grid-cols-12 rounded bg-white p-4 mb-4">
      <div className="col-span-12 lg:col-span-7">
        <div className=" lg:text-left mb-2 col-span-7 lg:col-span-12 text-sm font-semibold lg:font-semibold">
          <InfoOutlinedIcon
            variant="outlined"
            style={{ verticalAlign: "text-bottom", fontSize: 16 }}
            className="text-xs mr-1 leading-normal"
          />
          Information om hotellet
        </div>

        {post?.meta?.hotel_custom_meta ? (
          post.meta.hotel_custom_meta.map((option) => (
            <>
              <div className="grid grid-cols-12 col-span-12 text-left mb-1">
                <div className="col-span-7 text-xs font-light">
                  {option.name}
                </div>
                <div className="text-xs col-span-5 ">{option.value}</div>
              </div>
            </>
          ))
        ) : (
          <div className="grid grid-cols-12 col-span-12 text-left mb-1">
            <div className="col-span-7 text-xs font-light">Ingen detaljer</div>
          </div>
        )}
      </div>
      <div className="col-span-12 lg:col-span-5 mb-4">
        <div className="col-span-12 grid grid-cols-12 text-left">
          <div className=" lg:text-left mb-2 col-span-7 lg:col-span-12 text-sm font-semibold lg:font-semibold">
            <HotelOutlinedIcon
              variant="outlined"
              style={{ verticalAlign: "text-bottom", fontSize: 16 }}
              className="text-sm mr-1 leading-normal"
            />
            Prisen inkluderer
          </div>
          <div className="col-span-5 lg:col-span-12 text-xs grid grid-cols-12 gap-0 lg:gap-1">
            {departure_airport_code_for_departure &&
              arrival_airport_code_for_departure &&
              destination_code !== "GNB" &&
              destination_code !== "SZA" && (
                <div
                  className={`lg:leading-normal text-xs lg:text-gray-700 lg:font-normal col-span-12 lg:col-span-6 lg:text-center lg:rounded-xl border-dashed lg:border border-gray-300`}
                >
                  Transfer
                </div>
              )}
            {post?.meta?.hotel_meta_options &&
              post.meta.hotel_meta_options.map((option, index) => (
                <div
                  key={index}
                  className={`lg:leading-normal text-xs lg:text-gray-700 lg:font-normal col-span-12 lg:col-span-6 lg:text-center lg:rounded-xl border-dashed lg:border border-gray-300`}
                >
                  {option.label}
                </div>
              ))}
          </div>
        </div>
      </div>{" "}
    </div>
  ) : (
    <div className="grid grid-cols-12 rounded bg-white p-4 mb-4">
      <div className="col-span-12 lg:col-span-7">
        <div className=" lg:text-left mb-2 col-span-7 lg:col-span-12 text-sm font-semibold lg:font-semibold">
          <InfoOutlinedIcon
            variant="outlined"
            style={{ verticalAlign: "text-bottom", fontSize: 16 }}
            className="text-xs mr-1 leading-normal"
          />
          Information om hotellet
        </div>
        <div className="grid grid-cols-12 col-span-12 text-left mb-1">
          <div className="col-span-7 text-xs font-light">
            Distance til centrum
          </div>
          <div className="text-xs col-span-5 ">
            {post?.meta?.hotel_meta_distance_center} m.
          </div>
        </div>
        <div className="grid grid-cols-12 col-span-12 text-left mb-1">
          <div className="col-span-7 text-xs font-light">Distance til lift</div>
          <div className="col-span-5 text-xs">
            {post?.meta?.hotel_meta_distance_lift} m.
          </div>
        </div>
        <div className="grid grid-cols-12 col-span-12 text-left mb-1">
          <div className="col-span-7 text-xs font-light">
            Distance til skibus
          </div>
          <div className="col-span-5 text-xs">
            {post?.meta?.hotel_meta_distance_skibus} m.
          </div>
        </div>
        <div className="grid grid-cols-12 col-span-12 text-left mb-1">
          <div className="col-span-7 text-xs font-light">Internet</div>
          <div className="col-span-5 text-xs">
            {post?.meta?.hotel_meta_internet}
          </div>
        </div>
        <div className="grid grid-cols-12 col-span-12 text-left mb-1">
          <div className="col-span-7 text-xs font-light">Pension</div>
          <div className="col-span-5 text-xs">
            {post?.meta?.hotel_meta_pension?.label &&
              post.meta.hotel_meta_pension.label}
          </div>
        </div>
      </div>
      <div className="col-span-12 lg:col-span-5 mb-4">
        <div className="col-span-12 grid grid-cols-12 text-left">
          <div className=" lg:text-left mb-2 col-span-7 lg:col-span-12 text-sm font-semibold lg:font-semibold">
            <HotelOutlinedIcon
              variant="outlined"
              style={{ verticalAlign: "text-bottom", fontSize: 16 }}
              className="text-sm mr-1 leading-normal"
            />
            Prisen inkluderer
          </div>
          <div className="col-span-5 lg:col-span-12 text-xs grid grid-cols-12 gap-0 lg:gap-1">
            {departure_airport_code_for_departure &&
              arrival_airport_code_for_departure &&
              destination_code !== "GNB" &&
              destination_code !== "SZA" && (
                <div
                  className={`lg:leading-normal text-xs lg:text-gray-700 lg:font-normal col-span-12 lg:col-span-6 lg:text-center lg:rounded-xl border-dashed lg:border border-gray-300`}
                >
                  Transfer
                </div>
              )}
            {post?.meta?.hotel_meta_options &&
              post.meta.hotel_meta_options.map((option, index) => (
                <div
                  key={index}
                  className={`lg:leading-normal text-xs lg:text-gray-700 lg:font-normal col-span-12 lg:col-span-6 lg:text-center lg:rounded-xl border-dashed lg:border border-gray-300`}
                >
                  {option.label}
                </div>
              ))}
          </div>
        </div>
      </div>{" "}
    </div>
  );
};

export default HotelMetaComponent;

import React from "react";
import { PuffLoader } from "react-spinners";

const Loading = ({ loading = false, color }) => {
  return (
    <>
      <PuffLoader
        loading={loading ? true : false}
        css={{
          position: "fixed",
          zIndex: "9997",
          left: "50%",
          top: "25%",
          transform: "translate(-50%, -50%)",
        }}
        color={color}
        size={150}
      />
      {loading && (
        <h6
          style={{
            opacity: "0.8",
            color: "#888",
            position: "fixed",
            zIndex: "9998",
            left: "50%",
            top: "25%",
            lineHeight: "1.1",
            fontWeight: "700",
            textTransform: "uppercase",
            fontSize: "14px",
            letterSpacing: "0",
            transform: "translate(-50%, -50%)",
          }}
        >
          Henter data
        </h6>
      )}
    </>
  );
};

export default Loading;

import { FormControl } from "@material-ui/core";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { scroller } from "react-scroll";
import { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import globalContext from "../context/global/globalContext";
import TripSpecial from "../components/TripSpecial";
import Details from "../components/Details";
import DetailsUcpa from "../components/DetailsUcpa";

import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";

import { ToggleButton } from "@material-ui/lab";
import { ToggleButtonGroup } from "@material-ui/lab";

import handleViewport from "react-in-viewport";

import axios from "axios";
import { API_URL, API_ENDPOINT_STRING, BRAND_ID } from "../constants";

import {
  SET_TRIPS,
  SET_CURRENT_COMBINATIONS,
  SET_CURRENT_HOTEL,
  SET_CURRENT_TRIP,
} from "../context/types";

const Specials = ({ special_type }) => {
  const { dispatch, trips, currentTrip, gettingTrips, gotTrips } =
    useContext(globalContext);

  const LoaderBlock = (props) => {
    const { forwardedRef } = props;
    return <div className="viewport-block" ref={forwardedRef}></div>;
  };

  const history = useHistory();

  const ViewportLoaderBlock = handleViewport(
    LoaderBlock /** options: {}, config: {} **/
  );

  const [specialsTransport, setSpecialsTransport] = useState("2");
  const [specialsDuration, setSpecialsDuration] = useState("7");
  const [specialsDate, setSpecialsDate] = useState("*");

  const [specialsOffset, setSpecialsOffset] = useState(0);
  const [specialsHadResults, setSpecialsHadResults] = useState(true);
  const [specialsDates, setSpecialsDates] = useState(false);

  const getCombinations = async () => {
    if (specialsHadResults || specialsOffset === 0) {
      setSpecialsHadResults(false);
      try {
        const { data } = await axios.get(
          `${API_URL}/wp-json/${API_ENDPOINT_STRING}/v1/${special_type}?transport=transport_${specialsTransport}&duration=${specialsDuration}&date=${specialsDate}&offset=${specialsOffset}`
        );

        setSpecialsDates(data.dates);

        setSpecialsHadResults(
          data.result && data.result.length > 0 ? true : false
        );

        dispatch({
          type: SET_TRIPS,
          payload:
            trips && specialsOffset !== 0
              ? trips.concat(data.result)
              : data.result,
        });
      } catch (error) {}
    }
  };

  const handleLoadMore = () => {
    if (specialsHadResults) setSpecialsOffset(specialsOffset + 20);
  };

  const resetCurrentTrip = () => {
    history.push();
    dispatch({
      type: SET_CURRENT_COMBINATIONS,
      payload: false,
    });
    dispatch({
      type: SET_CURRENT_TRIP,
      payload: false,
    });
    dispatch({
      type: SET_CURRENT_HOTEL,
      payload: false,
    });
  };

  const handleTransportChange = (event, newTransport) => {
    if (newTransport !== null) {
      resetCurrentTrip();

      setSpecialsOffset(0);
      setSpecialsTransport(newTransport);
      setSpecialsDate("*");
    }
  };

  const handleDurationChange = (event, newDuration) => {
    if (newDuration !== null) {
      resetCurrentTrip();
      setSpecialsOffset(0);
      setSpecialsDuration(newDuration);
      setSpecialsDate("*");
    }
  };

  const handleDateChange = (event, newDate) => {
    if (newDate !== null) {
      resetCurrentTrip();

      setSpecialsOffset(0);
      setSpecialsDate(event.target.value);
    }
  };

  useEffect(() => {
    getCombinations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specialsTransport, specialsDuration, specialsDate, specialsOffset]);

  useEffect(() => {
    if (trips && specialsOffset === 0) {
      scroller.scrollTo("trips", {
        duration: 1200,
        offset: -150,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    }
  }, [specialsOffset, trips]);

  useEffect(() => {
    if (currentTrip) {
      scroller.scrollTo("trip", {
        duration: 1200,
        offset: -150,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    }
  }, [currentTrip]);

  return (
    <>
      <div className={trips ? "trip-search-trips" : "trip-search-home"}>
        {trips && (
          <div className="trip-search-specials mb-4 grid grid-cols-12">
            <FormControl
              className="col-span-12 lg:col-span-5"
              variant="outlined"
            >
              <InputLabel shrink={true} id="SpecialsTransportSelect">
                Transport
              </InputLabel>
              <ToggleButtonGroup
                className="col-span-12 grid grid-cols-12 mb-4 lg:mb-0 mr-0 lg:mr-12"
                color="primary"
                exclusive
                value={specialsTransport}
                onChange={handleTransportChange}
              >
                <ToggleButton
                  className="col-span-4"
                  style={{
                    fontSize: "13px",
                    letterSpacing: "0px",
                    fontWeight: "400",
                    width: "100%",
                  }}
                  value="2"
                >
                  <FlightTakeoffIcon
                    style={{ fontSize: "16px", marginRight: "5px" }}
                  />
                  Fly
                </ToggleButton>
                <ToggleButton
                  className="col-span-4"
                  style={{
                    fontSize: "13px",
                    letterSpacing: "0px",
                    fontWeight: "400",
                    width: "100%",
                  }}
                  value="1"
                >
                  <DirectionsBusIcon
                    style={{ fontSize: "16px", marginRight: "5px" }}
                  />
                  Bus
                </ToggleButton>
                <ToggleButton
                  className="col-span-4"
                  style={{
                    fontSize: "13px",
                    letterSpacing: "0px",
                    fontWeight: "400",
                    width: "100%",
                  }}
                  value="0"
                >
                  <DirectionsCarIcon
                    style={{ fontSize: "16px", marginRight: "5px" }}
                  />
                  Kør selv
                </ToggleButton>
              </ToggleButtonGroup>
            </FormControl>
            <FormControl
              className="col-span-12 lg:col-span-5"
              variant="outlined"
            >
              <InputLabel shrink={true} id="SpecialsDurationSelect">
                Rejselængde
              </InputLabel>
              <ToggleButtonGroup
                className="col-span-12 grid grid-cols-10 mb-4 lg:mb-0 mr-0 lg:mr-12"
                color="primary"
                exclusive
                value={specialsDuration}
                onChange={handleDurationChange}
              >
                <ToggleButton
                  className="col-span-2"
                  style={{
                    fontSize: "13px",
                    letterSpacing: "0px",
                    fontWeight: "400",
                    width: "100%",
                  }}
                  value="2"
                >
                  2 dage
                </ToggleButton>
                <ToggleButton
                  className="col-span-2"
                  style={{
                    fontSize: "13px",
                    letterSpacing: "0px",
                    fontWeight: "400",
                    width: "100%",
                  }}
                  value="3"
                >
                  3 dage
                </ToggleButton>
                <ToggleButton
                  className="col-span-2"
                  style={{
                    fontSize: "13px",
                    letterSpacing: "0px",
                    fontWeight: "400",
                    width: "100%",
                  }}
                  value="4"
                >
                  4 dage
                </ToggleButton>
                <ToggleButton
                  className="col-span-2"
                  style={{
                    fontSize: "13px",
                    letterSpacing: "0px",
                    fontWeight: "400",
                    width: "100%",
                  }}
                  value="5"
                >
                  5 dage
                </ToggleButton>
                <ToggleButton
                  className="col-span-2"
                  style={{
                    fontSize: "13px",
                    letterSpacing: "0px",
                    fontWeight: "400",
                    width: "100%",
                  }}
                  value="7"
                >
                  7 dage
                </ToggleButton>
              </ToggleButtonGroup>
            </FormControl>
            <FormControl
              className="col-span-12 lg:col-span-2 mg-4 lg:mb-0"
              variant="outlined"
            >
              <InputLabel shrink={true} id="SpecialsDateSelect">
                Afrejsedato
              </InputLabel>
              <Select
                SelectDisplayProps={{
                  style: {
                    paddingTop: 12.5,
                    paddingBottom: 12.5,
                  },
                }}
                style={{
                  borderRadius: 5,
                  border: "1px solid rgba(0,0,0,0.12)",
                }}
                label="SpecialsDateSelect"
                id="demo-simple-select"
                variant="filled"
                disableUnderline
                value={specialsDate ? specialsDate : specialsDates[0]}
                onChange={handleDateChange}
              >
                <MenuItem style={{ display: "flex" }} value={"*"}>
                  Alle datoer
                </MenuItem>
                {specialsDates &&
                  specialsDates.map((tripdate, index) => (
                    <MenuItem
                      style={{ display: "flex" }}
                      key={index}
                      value={tripdate}
                    >
                      {tripdate}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
        )}
        {currentTrip && BRAND_ID !== 5 && (
          <div id="trip">
            <Details />
          </div>
        )}
        {currentTrip && BRAND_ID === 5 && (
          <div id="trip">
            <DetailsUcpa />
          </div>
        )}

        {trips && (
          <>
            <div id="trips">
              {trips.map(
                (trip, index) =>
                  (!currentTrip ||
                    currentTrip.accomodation_code !==
                      trip.accomodation_code) && (
                    <TripSpecial key={index} trip={trip} />
                  )
              )}
            </div>

            <ViewportLoaderBlock onEnterViewport={() => handleLoadMore()} />
          </>
        )}

        {(!gettingTrips && !trips && gotTrips) ||
          (trips && trips.length === 0 && (
            <div
              id="trips"
              className="relative mt-10 mb-2 p-6 border text-center border-solid border-1 border-gray-300 rounded bg-gray-100"
            >
              <h6 className="font-bold mb-4">
                Der er desværre ingen rejser der passer til din søgning
              </h6>
              <p>
                Du kan altid kontakte os på{" "}
                <a href="tel:70100010">70 10 00 10</a> så vi kan finde en rejse
                til dig
              </p>
            </div>
          ))}
      </div>
    </>
  );
};

export default Specials;

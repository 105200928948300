import React, { useContext } from "react";
import OrderAccountHolderForm from "../components/OrderAccountHolderForm";
import OrderTotal from "../components/OrderTotal";
import globalContext from "../context/global/globalContext";

const OrderAccountHolder = () => {
  const { order } = useContext(globalContext);

  return (
    <>
      <div className="grid grid-cols-12">
        <OrderAccountHolderForm tailwindCSS="col-span-12 lg:col-span-8 pr-0 mb-5 md:mb-0 md:pr-6" />
        <OrderTotal
          order={order && order}
          tailwindCSS="col-span-12 lg:col-span-4"
        />
      </div>
    </>
  );
};

export default OrderAccountHolder;

import React from "react";

import ArrowForwardOutlinedIcon from "@material-ui/icons/ArrowForwardOutlined";
import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined";
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";
import FlightLandIcon from "@material-ui/icons/FlightLand";

import format from "date-fns/format";
import da from "date-fns/locale/da";

const HotelTravelDetailsComponent = ({ trip }) => {
  const {
    departure_date,
    return_date,
    arrival_airport_code_for_departure,
    arrival_airport_code_for_return,
    departure_airport_code_for_departure,
    departure_airport_code_for_return,
    departure_time_for_departure,
    departure_time_for_return,
  } = trip;
  return (
    <div className="grid grid-cols-12 lg:w-full gap-0 lg:gap-2 text-xs  bg-white p-4">
      <div className="col-span-12 lg:col-span-6 grid grid-cols-12 ">
        <div className="col-span-5 lg:col-span-5 font-bold text-gray-800">
          <ArrowForwardOutlinedIcon
            variant="outlined"
            style={{ fontSize: 15 }}
            className="text-xs mr-2 text-gray-800"
          />
          Afrejse
        </div>
        <div className="col-span-7 lg:col-span-7 capitalize ">
          {format(new Date(departure_date), "eeee, d MMMM yyyy", {
            locale: da,
          })}
        </div>
      </div>
      <div className="col-span-12 lg:col-span-6 grid grid-cols-12">
        <div className="col-span-5 lg:col-span-5 font-bold text-gray-800">
          <ArrowBackOutlinedIcon
            variant="outlined"
            style={{ fontSize: 15 }}
            className="text-xs mr-2 text-gray-800"
          />
          Hjemrejse
        </div>
        <div className="col-span-7 lg:col-span-7 capitalize ">
          {format(new Date(return_date), "eeee, d MMMM yyyy", {
            locale: da,
          })}
        </div>
      </div>
      {departure_airport_code_for_departure &&
        arrival_airport_code_for_departure && (
          <div className="col-span-12 lg:col-span-6 grid grid-cols-12">
            <div className="col-span-5 lg:col-span-5 font-bold text-gray-800">
              <FlightTakeoffIcon
                variant="outlined"
                style={{ fontSize: 15 }}
                className="text-xs mr-2 text-gray-800"
              />
              {departure_airport_code_for_departure} -{" "}
              {arrival_airport_code_for_departure}
            </div>
            <div className="col-span-7 lg:col-span-7 capitalize ">
              {format(
                new Date(departure_time_for_departure),
                "d MMMM yyyy HH:mm",
                {
                  locale: da,
                }
              )}
            </div>
          </div>
        )}
      {departure_airport_code_for_return && arrival_airport_code_for_return && (
        <div className="col-span-12 lg:col-span-6 grid grid-cols-12">
          <div className="col-span-5 lg:col-span-5 font-bold text-gray-800">
            <FlightLandIcon
              variant="outlined"
              style={{ fontSize: 15 }}
              className="text-xs mr-2 text-gray-800"
            />
            {departure_airport_code_for_return} -{" "}
            {arrival_airport_code_for_return}
          </div>
          <div className="col-span-7 lg:col-span-7 capitalize ">
            {format(new Date(departure_time_for_return), "d MMMM yyyy HH:mm", {
              locale: da,
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default HotelTravelDetailsComponent;
